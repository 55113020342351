import { getPlans } from '@/services/plans';

export default {
    namespaced: true,
    state() {
        return {
            plans: [], // Store plans here
            currentPlan: null // Store current plan details
        };
    },
    mutations: {
        SET_PLANS(state, plans) {
            state.plans = plans; // Update the state with fetched plans
        },
        SET_CURRENT_PLAN(state, plan) {
            state.currentPlan = plan; // Update current plan details
        },
    },
    actions: {
        async fetchPlans({ commit }) {
            try {
                const plans = await getPlans(); // Fetch plans from the API
                commit('SET_PLANS', plans); // Commit the plans to the state
            } catch (error) {
                console.error('Error fetching plans:', error);
            }
        },
    },
    getters: {
        plans: (state) => state.plans, // Getter to access plans from state
        currentPlan: (state) => state.currentPlan, // Getter to access the current plan
        getPlanById: (state) => (id) => {
            // Use 'item_id' instead of 'id' based on the structure
            return state.plans.find(plan => plan.item_id === id);
        },
        hasPreviousPlan: (state) => (currentPlanId) => {
            const index = state.plans.findIndex(plan => plan.item_id === currentPlanId);
            return index > 0;
        },
        hasNextPlan: (state) => (currentPlanId) => {
            const index = state.plans.findIndex(plan => plan.item_id === currentPlanId);
            return index >= 0 && index < state.plans.length - 1;
        },
        getAdjacentPlanId: (state) => (currentPlanId, direction) => {
            const index = state.plans.findIndex(plan => plan.item_id === currentPlanId);
            if (direction === 'previous' && index > 0) {
                return state.plans[index - 1].item_id;
            }
            if (direction === 'next' && index < state.plans.length - 1) {
                return state.plans[index + 1].item_id;
            }
            return null;
        },
    },
};