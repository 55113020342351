<template>
  <bottom-navbar />
  <cursor />

  <!-- Notification Icon with router-link and Tailwind CSS -->
  <router-link
    to="/inbox"
    class="z-50 m-0 top-0 h-[3rem] fixed w-full bg-[#e8e8e8] text-lg text-gray-800"
  >
    <div class="flex items-center justify-between p-3 px-5">
      <h3><i>Health App</i></h3>
      <i class="fa fa-bell"></i>
    </div>
  </router-link>
  <!-- Carousel with Pagination, Autoplay, and Navigation -->
  <div class="carousel-container relative mx-auto mt-[3rem]">
    <carousel
      :itemsToShow="1"
      :wrapAround="true"
      :paginationEnabled="true"
      :navigationEnabled="true"
      :autoplay="2000"
      class="h-full"
    >
      <slide v-for="(item, index) in carouselItems" :key="index">
        <img
          :src="item.image"
          :alt="item.name"
          class="w-full h-full object-cover"
        />
      </slide>
      <template #addons>
        <pagination
          class="absolute bottom-0 left-0 right-0 flex justify-center pb-2"
        />
      </template>
    </carousel>
  </div>

  <!-- Loading indicator -->
  <div v-if="isLoading" class="flex justify-center items-center h-[30rem]">
    <i class="fa fa-spinner fa-spin text-4xl text-gray-800"></i>
  </div>

  <!-- Carousel with Pagination, Autoplay, and Navigation -->
  <div class="carousel-container !mb-16 relative mx-auto mt-[3rem]">
    <h3 class="p-3">
      <i>Your Prescription </i>
    </h3>
    <carousel
      :itemsToShow="3"
      :wrapAround="true"
      :paginationEnabled="true"
      :navigationEnabled="true"
      :autoplay="2000"
      class="space-x-3 h-[15rem] !z-0 mb-10"
    >
      <slide
        class="block mx-1 !z-0 bg-[white] p-2 rounded-sm"
        v-for="(item, index) in homeData.Prescription"
        :key="index"
      >
        <div class="bg-[white] rounded-md">
          <img
            :src="item.image"
            :alt="item.name"
            class="w-full h-f10 object-cover"
          />
        </div>
        <div class="text-center text-balck">{{ item.name }}</div>
      </slide>
    </carousel>
  </div>
  <div
    v-if="homeData.Recomandation?.length"
    class="carousel-container relative mx-auto mt-[3rem]"
  >
    <h3 class="p-3">
      <i>Recomandation </i>
    </h3>
    <carousel
      :itemsToShow="3"
      :wrapAround="true"
      :paginationEnabled="true"
      :navigationEnabled="true"
      :autoplay="2000"
      class="space-x-3 !z-0"
    >
      <slide
        class="block mx-1 !z-0 bg-[white] p-2 rounded-sm"
        v-for="(item, index) in homeData.Recomandation"
        :key="index"
      >
        <div class="bg-[white] rounded-md">
          <img
            :src="item.image"
            :alt="item.name"
            class="w-full h-f10 object-cover"
          />
        </div>
        <div class="text-center text-balck">{{ item.name }}</div>
      </slide>
    </carousel>
  </div>
  <div
    v-if="homeData['Our Recomandations']?.length"
    class="carousel-container relative mt-5 mx-auto"
  >
    <h3 class="p-3">
      <i>Our Recomandation </i>
    </h3>
    <carousel
      :itemsToShow="3"
      :wrapAround="true"
      :navigationEnabled="true"
      :autoplay="2000"
      class="space-x-3 !z-0"
    >
      <slide
        class="block mx-1 !z-0 bg-[white] p-2 rounded-sm"
        v-for="(item, index) in homeData.Our_Recomandations"
        :key="index"
      >
        <div class="bg-[white] rounded-md">
          <img
            :src="item.image"
            :alt="item.name"
            class="w-full h-f10 object-cover"
          />
        </div>
        <div class="text-center text-balck">{{ item.name }}</div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { homeData } from "@/services/dashboard"; // Adjust based on your actual service path

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      homeData: { Recomandation: [], Prescription: [], Our_Recomandations: [] }, // Assuming the structure based on your provided data
      carouselItems: [
        {
          image: require("@/assets/img/bg/slider1.webp"),
          name: "Slide 1",
        },
        {
          image: require("@/assets/img/bg/slider2.webp"),
          name: "Slide 2",
        },
        {
          image: require("@/assets/img/bg/slider3.webp"),
          name: "Slide 3",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const data = await homeData(); // Fetch data using your service
        this.homeData = data.data[0];
        this.isLoading = false;
        console.log(JSON.parse(JSON.stringify(this.homeData)));
      } catch (error) {
        console.error("Error fetching home data:", error);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.carousel-container {
  max-width: 600px;
  overflow: hidden;
  position: relative; /* Needed for absolute positioning of pagination */
}

.carousel__pagination {
  bottom: 0; /* Position at the bottom of the carousel */
}

.carousel__pagination-button {
  width: 12px; /* Size of the bullet */
  height: 12px; /* Size of the bullet */
  background-color: #ccc; /* Default color */
  border-radius: 50%; /* Makes it circular */
  transition: background-color 0.3s;
}

.carousel__pagination-button--active {
  background-color: #333; /* Active color */
}
</style>
