<template>
  <!-- Footer -->
  <footer class="footer footer-one">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4">
            <div class="footer-widget footer-about">
              <div class="footer-logo">
                <router-link to="/"
                  ><img
                    src="assets/img/general/newboxes_logo_black.svg"
                    alt="logo"
                /></router-link>
              </div>
              <div class="footer-about-content">
                <p>
                  Effortlessly schedule your medical appointments with FranxLab.
                  Connect with healthcare professionals, manage appointments &
                  prioritize your well being
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-3 col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Company</h2>
                  <ul>
                    <li><router-link to="/home">Home</router-link></li>
                    <!--                    <li><router-link to="/home">Authentication</router-link></li>-->
                    <!--                    <li><router-link to="search">Specialities</router-link></li>-->
                    <!--                    <li><router-link to="video-call">Video Consult</router-link></li>-->
                  </ul>
                </div>
              </div>
              <div class="col-lg-3 col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Specialities</h2>
                  <ul>
                    <!--                    <li><router-link to="search">Neurology</router-link></li>-->
                    <!--                    <li><router-link to="search">Cardiologist</router-link></li>-->
                    <!--                    <li><router-link to="search">Dentist</router-link></li>-->
                  </ul>
                </div>
              </div>
              <div class="col-lg-6 col-md-4">
                <div class="footer-widget footer-contact">
                  <h2 class="footer-title">Contact Us</h2>
                  <div class="footer-contact-info">
                    <div class="footer-address">
                      <p>
                        <i class="feather-map-pin"></i> 3556 Beech Street, USA
                      </p>
                    </div>
                    <div class="footer-address">
                      <p><i class="feather-phone-call"></i> +1 315 369 5943</p>
                    </div>
                    <div class="footer-address mb-0">
                      <p><i class="feather-mail"></i> healthapp@example.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-7">
            <div class="footer-widget">
              <h2 class="footer-title">Join Our Newsletter</h2>
              <div class="subscribe-form">
                <form action="#">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Enter Email"
                  />
                  <button type="submit" class="btn">Submit</button>
                </form>
              </div>
              <div class="social-icon">
                <ul>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-facebook"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-instagram"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-twitter"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-linkedin-in"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="copyright-text">
                <p class="mb-0">
                  Copyright © {{ new Date().getFullYear() }} HealthApp. All
                  Rights Reserved
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <!-- Copyright Menu -->
              <div class="copyright-menu">
                <ul class="policy-menu">
                  <li><router-link to="#">Privacy Policy</router-link></li>
                  <li>
                    <router-link to="#">Terms and Conditions</router-link>
                  </li>
                </ul>
              </div>
              <!-- /Copyright Menu -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
  </footer>
  <!-- /Footer -->
</template>
