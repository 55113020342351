<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1 :title="title" :text="text" :text1="text1" />

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <!-- Profile Sidebar -->
          <div class="col-lg-4 col-xl-3 theiaStickySidebar">
            <profilesidebar></profilesidebar>
          </div>
          <!-- / Profile Sidebar -->
          <div class="col-lg-8 col-xl-9">
            <div class="dashboard-header">
              <div class="header-back">
                <router-link
                  v-if="routerLinkTo"
                  :to="routerLinkTo"
                  class="back-arrow"
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </router-link>
                <h3>Plans Item</h3>
              </div>
            </div>
            <div class="appointment-details-wrap">
              <!-- Disclaimer Section -->
              <div class="disclaimer">
                <p>
                  <strong>Disclaimer:</strong> The information provided on this
                  website is for informational purposes only and is not intended
                  as medical advice. Always consult with a qualified healthcare
                  provider before starting any new supplement, especially if you
                  have any health concerns or conditions. The effectiveness and
                  safety of dietary supplements may vary, and only a healthcare
                  provider can offer personalized recommendations based on your
                  individual health needs.
                </p>
              </div>
              <!-- Plan Detail Card -->
              <div class="appointment-wrap appointment-detail-card">
                <div class="detail-card-image">
                  <img :src="currentPlan.image" alt="Item Image" />
                </div>
                <div v-if="currentPlan" class="detail-card-bottom-info">
                  <ul>
                    <li>
                      <div class="patinet-information mb-3">
                        <div class="patient-info">
                          <h2>{{ currentPlan.name }}</h2>
                          <h6>made in Germany</h6>
                          <div class="rating">
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled ms-1"></i>
                            <i class="fas fa-star filled ms-1"></i>
                            <i class="fas fa-star filled ms-1"></i>
                            <i class="fas fa-star ms-1"></i>
                            <span class="d-inline-block average-rating ms-1"
                              ># Reviews</span
                            >
                          </div>
                          <!--                          <br/>-->

                          <div class="size-selector mt-3 mb-1">
                            <label v-for="size in sizes" :key="size.id">
                              <input
                                type="radio"
                                :value="size"
                                v-model="selectedSize"
                              />
                              <span
                                :class="{
                                  'selected-size-item': isSelected(size),
                                }"
                                class="size-wrap"
                              >
                                <span class="size-name"
                                  >{{ size.size }} {{ size.unit }}
                                </span>
                                <br />
                                <span class="size-price"
                                  >€{{ size.price }}</span
                                >
                              </span>
                            </label>
                            <p v-if="errorMessage" class="error-message">
                              {{ errorMessage }}
                            </p>
                          </div>
                          <!--
                          <div v-if="errorMessage">
                            <br/>
                          </div>
-->
                          <!--                          <span>Price: <strong>€{{ totalPrice }}</strong></span>-->

                          <div class="start-btn">
                            <button
                              @click="addToCart(currentPlan)"
                              class="btn btn-success add-to-cart-current-item"
                            >
                              Add to Cart
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="patinet-information" style="display: block">
                        <p>{{ currentPlan.description }}</p>
                      </div>
                    </li>
                  </ul>

                  <!--          Collapsible Section                -->
                  <div class="collapsible-section-info">
                    <ul>
                      <li
                        v-if="currentPlan.general_dosage"
                        @click="toggleSection('dosage')"
                        class="collapsible-section-item collapsible-section-dosage-wrap-title"
                      >
                        <h4 class="collapsible-section-dosage">Dosage</h4>
                        <i
                          :class="
                            isOpen.dosage
                              ? 'fa fa-chevron-up rotate'
                              : 'fa fa-chevron-down'
                          "
                          class="toggle-icon"
                        ></i>
                      </li>
                      <transition
                        name="fade"
                        @before-enter="beforeEnter"
                        @enter="enter"
                        @leave="leave"
                      >
                        <li
                          v-if="isOpen.dosage"
                          class="collapsible-section-content collapsible-section-dosage-text"
                        >
                          <div class="dosage-instructions">
                            <safety-warning />
                            <div class="instruction-item">
                              <!--                              <img v-if="instruction.image" :src="require(`assets/img//blog/${instruction.image}`)" alt="Visual Aid" class="visual-aid" />-->
                              <!--                              <i v-if="instruction.icon" :class="instruction.icon" class="visual-aid"></i>-->
                              <p>{{ currentPlan.general_dosage }}</p>
                            </div>
                          </div>
                          <!--                          <p>
                            Discover the power of our all-natural supplement, designed to boost your energy levels, enhance mental clarity, and support overall well-being. Packed with essential vitamins and minerals, it’s the perfect addition to your daily routine. Experience the difference and take a step towards a healthier, more vibrant you.
                          </p>-->
                        </li>
                      </transition>
                      <li
                        v-if="currentPlan.general_instructions"
                        @click="toggleSection('instructions')"
                        class="collapsible-section-item collapsible-section-instructions-wrap-title"
                      >
                        <h4 class="collapsible-section-instructions">
                          Instructions
                        </h4>
                        <i
                          :class="
                            isOpen.instructions
                              ? 'fa fa-chevron-up rotate'
                              : 'fa fa-chevron-down'
                          "
                          class="toggle-icon"
                        ></i>
                      </li>
                      <transition
                        name="fade"
                        @before-enter="beforeEnter"
                        @enter="enter"
                        @leave="leave"
                      >
                        <li
                          v-if="isOpen.instructions"
                          class="collapsible-section-content collapsible-section-instructions-text"
                        >
                          <p>{{ currentPlan.general_instructions }}</p>
                        </li>
                      </transition>
                    </ul>
                  </div>
                  <!--          End of Collapsible Section                -->
                </div>
              </div>
              <!-- /Plan Detail Card -->

              <latest-blog-posts></latest-blog-posts>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <doctor-footer></doctor-footer>

    <bottom-navbar />
  </div>
</template>
<script setup>
import { watch, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useCart } from "@/composables/useCart";

const route = useRoute();
const store = useStore();
const { addItem } = useCart();
const routerLinkTo = ref(null);

const planId = ref(parseInt(route.params.id));
const currentPlan = ref({});

const sizes = ref([]);
const selectedSize = ref(null);
const isOpen = ref({
  dosage: false,
  instructions: false,
});
const errorMessage = ref("");

// it change to dynamic fetching and it's just remain for having a sample for using data in template
// it will remove after approval of the related sprint

/*const instructions = ref([
  { text: 'Take one pill in the morning.', image: 'pill.jpeg' },
  { text: 'Use the provided measuring spoon.', icon: 'fas fa-spoon' }
]);*/

onMounted(async () => {
  if (store.getters["plans/plans"].length === 0) {
    await store.dispatch("plans/fetchPlans");
  }

  currentPlan.value = store.getters["plans/getPlanById"](planId.value);
  sizes.value = currentPlan.value.packages || [];

  if (sizes.value.length > 0) {
    // Automatically select the first size
    selectedSize.value = sizes.value[0];
  }

  if (!currentPlan.value) {
    console.error("Plan not found");
  }
});

// it is commented for now and will be remove after the related sprit successfully done
/*const totalPrice = computed(() => {
  return selectedSizes.value.reduce((total, size) => total + parseFloat(size.price), 0).toFixed(2);
});*/

// function capitalizeFirstLetterOfEachSentence(paragraph) {
//   if (!paragraph) return ""; // handle empty or null values
//   const sentences = paragraph.match(/[^.!?]+[.!?]*/g) || [];
//   return sentences
//     .map(
//       (sentence) => sentence.charAt(0).toUpperCase() + sentence.slice(1).trim()
//     )
//     .join(" ");
// }

function addToCart(item) {
  // Check if any size is selected, if not, fall back to the first size
  if (selectedSize.value.length === 0 && sizes.value.length > 0) {
    selectedSize.value = sizes.value[0];
  }

  // Add each selected size as a separate item in the cart
  addItem({
    ...item,
    quantity: 1,
    packageID: selectedSize.value.id,
    price: selectedSize.value.price,
  });

  // Clear error message if successful
  errorMessage.value = "";
}

watch(
  () => currentPlan.value.item_id,
  (newId) => {
    if (newId) {
      routerLinkTo.value = {
        name: "patient-morning-plan",
        params: { id: newId },
      };
    }
  },
  { immediate: true }
);

function isSelected(size) {
  return selectedSize.value.id === size.id;
}
// function isLast(size) {
//   return sizes.value.indexOf(size) === sizes.value.length - 1;
// }
function toggleSection(section) {
  isOpen.value[section] = !isOpen.value[section];
}

function beforeEnter(el) {
  el.style.maxHeight = "0";
  el.style.opacity = "0";
}

function enter(el) {
  el.offsetHeight; // Trigger reflow
  el.style.transition = "max-height 0.3s ease, opacity 0.3s ease";
  el.style.maxHeight = el.scrollHeight + "px";
  el.style.opacity = "1";
}

function leave(el) {
  el.style.transition = "max-height 0.3s ease, opacity 0.3s ease";
  el.style.maxHeight = "0";
  el.style.opacity = "0";
}
</script>
<style scoped>
.error-message {
  color: red !important;
  font-size: 14px;
  margin-top: 10px;
}
/* Disclaimer */
.disclaimer {
  padding: 15px;
  margin: 20px 0;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.disclaimer p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.disclaimer strong {
  font-weight: bold;
  color: red;
}
/* End of Disclaimer */
/* Sizes Section */
.size-selector {
  display: flex;
  align-items: center;
}

.size-selector label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

/* Hide the default radio button */
.size-selector input[type="radio"] {
  display: none;
}
/*.size-wrap .size-price, .size-wrap .size-name{
}*/
.size-wrap,
.size-wrap .size-price,
.size-wrap .size-name {
  background: #c7d6e3;
  font-weight: 500;
  font-size: 13px;
  padding: 4px 10px;
  color: #000;
  border-radius: 15px;
  transition: all 0.1s ease;
}
/*.size-wrap .size-name{
  padding-bottom: 20px;
  border-bottom: 1px dashed #cccccc !important;
}*/
.size-wrap {
  text-align: center;
}
.selected-size-item {
  border: 2px solid #0c599f;
}

.selected-size-item,
.selected-size-item .size-price,
.selected-size-item .size-name {
  font-weight: bold;
  background: #73a2cb;
  font-size: 13px;
  padding: 4px 10px;
  color: #000;
  border-radius: 15px;
}

.size-selector span {
  margin: 0 5px;
}

.size-selector span:last-of-type {
  margin-right: 0;
}
/* End of Sizes Section */
/*  Dosage Instructions */
.dosage-instructions {
  margin: 20px 0;
}
.instruction-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.visual-aid {
  margin-right: 10px;
  width: 24px; /* Adjust as needed */
  height: 24px; /* Adjust as needed */
}
.visual-aid.img {
  border-radius: 50%;
}
/*  End of Dosage Instructions */
/*  Collapsible Section  */

.collapsible-section-info {
  margin: 4% 0;
}

.collapsible-section-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1%;
}
.collapsible-section-dosage-text p,
.collapsible-section-instructions-text p {
  color: #6b7280 !important;
}
.toggle-icon {
  font-size: 16px;
  transition: transform 0.6s;
}

.rotate {
  transform: rotate(0deg);
}
.collapsible-section-content {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.6s ease, opacity 0.6s ease;
  padding: 0 10px;
}

/* When content is visible */
.collapsible-section-info .collapsible-section-content {
  max-height: 200px;
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: max-height 0.6s ease, opacity 0.6s ease;
}

.fade-enter,
.fade-leave-to {
  max-height: 0;
  opacity: 0;
}
/* End of Collapsable Section  */

.add-to-cart-current-item {
  width: 200px;
  margin-top: 20px;
}

.selected-size-item {
  font-weight: bold;
}

.detail-card-bottom-info {
  border-top: 1px none;
}

.plans-details-btn span {
  font-size: 16px !important;
  color: black !important;
}
.plans-details-btn-morning.active span,
.plans-details-btn-lunch.active span,
.plans-details-btn-evening.active span {
  color: white !important;
}

/* Latest Blog Posts  */
.our-blog-section.eye-blog {
  background: transparent;
}
/* End of Latest Blog Posts  */
</style>
