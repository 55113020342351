<template>
  <header class="header header-trans" v-bind:class="$route.meta.headerClass">
    <div class="container">
      <nav
        class="navbar navbar-expand-lg header-nav"
        v-bind:class="$route.meta.headerNavClass"
      >
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/" class="navbar-brand logo" v-if="isHomeFourRoute">
            <img
              src="assets/img//footer-logo.png"
              class="img-fluid"
              alt="Logo"
            />
          </router-link>

          <router-link to="/" class="navbar-brand logo" v-else>
            <img src="assets/img//logo.png" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="assets/img//logo.png" class="img-fluid" alt="Logo" />
            </router-link>
            <a
              id="menu_close"
              class="menu-close"
              href="javascript:void(0);"
              @click="closeSidebar"
            >
              <i class="fas fa-times"></i>
            </a>
          </div>
          <mainmenu></mainmenu>
        </div>

        <ul class="nav header-navbar-rht" v-if="isHomeTwoRoute">
          <li class="nav-item">
            <router-link class="nav-link header-login" to="/login"
              >login / Signup
            </router-link>
          </li>
        </ul>
        <ul
          class="nav header-navbar-rht right-menu"
          v-else-if="isHomeFour1Route"
        >
          <li class="nav-item">
            <router-link class="nav-link header-login white-bg" to="/login"
              >login / Signup</router-link
            >
          </li>
        </ul>
        <ul class="nav header-navbar-rht" v-else-if="isHomeSixRoute">
          <li class="login-in-fourteen">
            <router-link to="/register"
              ><img
                src="assets/img//icons/login-user-circle.svg"
                class="me-2"
                alt="Img"
              />Sign Up /
            </router-link>
            <router-link to="/login"> Sign In</router-link>
          </li>
        </ul>
        <ul class="nav header-navbar-rht" v-if="isHomeThree">
          <li class="contact-item">
            <i class="fa-solid fa-phone"></i>+1 315 369 5943
          </li>
          <li class="nav-item">
            <router-link class="nav-link header-login" to="login"
              ><img src="assets/img//icons/user-circle.svg" alt="img" />Login /
              Sign up
            </router-link>
          </li>
        </ul>
      </nav>
      <div
        class="sidebar-overlay"
        :class="{ opened: isSidebarOpen }"
        @click="closeSidebar"
      ></div>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      isSidebarOpen: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
  },
  computed: {},
};
</script>
