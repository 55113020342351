<template>
  <div class="account-page h-screen">
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="content top-space">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <!-- Login Tab Content -->
              <div class="">
                <div class="row align-items-center justify-content-center">
                  <!--                  <div class="col-md-7 col-lg-6 login-left">
                    <img
                      src="assets/img//login-banner.png"
                      class="img-fluid"
                      alt="Doccure Login"
                    />
                  </div>-->
                  <div class="col-md-12 col-lg-6 p-5">
                    <div class="login-header">
                      <h3>Access to Your Health</h3>
                      <!-- Display error message -->
                      <div v-if="errorMessage" class="error-message">
                        {{ errorMessage }}
                      </div>
                    </div>

                    <form @submit.prevent="submitForm">
                      <!-- Error Display Section -->
                      <div v-if="formErrors.length" class="alert alert-danger">
                        <ul>
                          <li v-for="(error, index) in formErrors" :key="index">
                            {{ error }}
                          </li>
                        </ul>
                      </div>

                      <div class="mb-3 form-focus">
                        <input
                          v-model="email"
                          type="email"
                          class="form-control floating"
                        />
                        <label class="focus-label">Email</label>
                      </div>
                      <div class="mb-3 form-focus">
                        <div class="input-group">
                          <input
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            class="form-control floating"
                          />
                          <button
                            type="button"
                            class="btn btn-outline-secondary"
                            @click="togglePasswordVisibility"
                          >
                            <i
                              :class="
                                showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'
                              "
                            ></i>
                          </button>
                          <label class="focus-label">Password</label>
                        </div>
                      </div>
                      <div class="mb-3 form-check">
                        <input
                          v-model="staySignedIn"
                          type="checkbox"
                          class="form-check-input"
                          id="staySignedIn"
                        />
                        <label class="form-check-label" for="staySignedIn">
                          Stay signed in
                        </label>
                        <small
                          class="form-text mt-2 text-muted"
                          style="color: red !important; display: block"
                        >
                          <i class="fas fa-info-circle"></i> For security
                          reasons, avoid using this option on public or shared
                          devices.
                        </small>
                      </div>
                      <div class="text-end">
                        <router-link class="forgot-link" to="/forgot-password"
                          >Forgot Password?</router-link
                        >
                      </div>
                      <button
                        class="w-full my-5 bg-black hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg text-lg"
                        type="submit"
                      >
                        Login
                      </button>
                      <div class="text-center dont-have">
                        Don’t have an account?
                        <router-link to="/register">Register</router-link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- /Login Tab Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <indexfooter />
      <!-- Loading Spinner -->
      <loading-spinner v-if="isLoading" />

      <!--      <bottom-navbar />-->
    </div>
  </div>
</template>
<script>
import { loginUser } from "@/services/auth";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      showPassword: false, // Data property to toggle password visibility
      staySignedIn: false, // Data property for stay signed in checkbox
      email: "",
      password: "",
      formErrors: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["errorMessage", "isAuthenticated"]),
  },
  beforeMount() {
    console.log("this.isAuthenticated::", this.isAuthenticated);
    // Check if already authenticated
    if (this.isAuthenticated) {
      this.$router.push("/plans");
    }
  },
  methods: {
    ...mapActions(["setRegisteredUser", "setIsAuthenticated", "setAuthToken"]),

    async submitForm() {
      this.isLoading = true;
      this.formErrors = [];

      // Validate email and password
      this.validateForm();
      if (this.formErrors.length > 0) {
        this.isLoading = false;
        return;
      }

      // If no errors, proceed with form submission
      if (this.formErrors.length === 0) {
        try {
          // Call the API to log in
          const { token, userData } = await loginUser({
            email: this.email,
            password: this.password,
          });

          // Store user data and authentication token in Vuex
          this.$store.dispatch("setRegisteredUser", userData); // Store user data
          this.$store.dispatch("setAuthToken", token); // Store token
          this.$store.dispatch("setIsAuthenticated", true); // Set authentication status
          // Set success message
          // this.$store.dispatch('setSuccessMessage', 'Login successful! Welcome to your dashboard.');
          localStorage.setItem("userData", JSON.stringify(userData));

          if (this.staySignedIn) {
            localStorage.setItem("token", token); // Store token for persistent login
          }
          this.$router.push("/plans");
        } catch (error) {
          // Handle login error
          console.error("Login failed:", error);
          this.isLoading = false;
          this.formErrors.push(error.message);
        } finally {
          this.isLoading = false;
        }
      }
    },
    validateForm() {
      if (!this.email) {
        this.formErrors.push("Email is required.");
      } else if (!this.validEmail(this.email)) {
        this.formErrors.push("Invalid email format.");
      }

      if (!this.password) {
        this.formErrors.push("Password is required.");
      } else if (this.password.length < 6) {
        this.formErrors.push("Password must be at least 6 characters long.");
      }
    },
    validEmail(email) {
      // Basic email validation regex
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    handleLogin() {
      this.$router.push("/2fa");
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  margin-bottom: 20px;
}

.login-header-img {
  margin-bottom: 5%;
}
/* Add any custom styles for the password visibility toggle button */
.input-group {
  position: relative;
}

.input-group .btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-radius: 0 0.25rem 0.25rem 0;
}

.input-group input {
  padding-right: 3rem; /* Adjust padding to accommodate the toggle button */
}

.alert {
  margin-bottom: 1rem;
}

.alert-danger {
  border-color: #dc3545;
  background-color: #f8d7da;
  color: #721c24;
}

.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  display: block;
  color: #dc3545;
}
</style>
