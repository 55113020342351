<template>
  <div class="bg-gray-100 text-gray-900 font-sans leading-normal">
    <!-- Back Button -->
    <button @click="goBack" class="back-arrow">
      <i class="fa-solid fa-chevron-left"></i>
    </button>
    <div class="container mx-auto p-8">
      <h1 class="text-3xl font-bold text-center mb-6">Imprint</h1>
      <div class="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
        <h2 class="text-2xl font-bold mb-4">Company Information</h2>
        <p>
          <strong>Company Name:</strong> HealthApp Inc.<br />
          <strong>Address:</strong> 1234 Wellness St, Healthy City, Country<br />
          <strong>Phone:</strong> +123 456 7890<br />
          <strong>Email:</strong> support@healthapp.com
        </p>

        <h3 class="text-xl font-bold mt-6 mb-2">Legal Representatives</h3>
        <p>John Doe, CEO<br />Jane Smith, CFO</p>

        <h3 class="text-xl font-bold mt-6 mb-2">Registration</h3>
        <p>
          Registered at the local commercial register under the number:
          12345678.
        </p>

        <h3 class="text-xl font-bold mt-6 mb-2">VAT Identification Number</h3>
        <p>DE 123456789</p>

        <h3 class="text-xl font-bold mt-6 mb-2">Dispute Resolution</h3>
        <p>
          We are not obliged or willing to participate in a dispute resolution
          procedure before a consumer arbitration board.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Imprint",
  methods: {
    goBack() {
      this.$router.go(-1); // Navigates back to the previous route
    },
  },
};
</script>
