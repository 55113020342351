<template>
  <div class="bg-gray-100 text-gray-900 font-sans leading-normal">
    <!-- Back Button -->
    <button @click="goBack" class="back-arrow">
      <i class="fa-solid fa-chevron-left"></i>
      <!-- Using a Chevron Icon -->
    </button>
    <div class="container mx-auto p-8">
      <h1 class="text-3xl font-bold text-center mb-6">Terms of Use</h1>
      <div class="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
        <h2 class="text-2xl font-bold mb-4">Welcome to our Health App</h2>
        <p>
          By using this app, you agree to the following terms and conditions.
          This app is designed to provide health and wellness information and is
          not a substitute for professional medical advice, diagnosis, or
          treatment. Always seek the advice of your physician or other qualified
          health provider with any questions you may have regarding a medical
          condition.
        </p>
        <h3 class="text-xl font-bold mt-6 mb-2">1. Use of the App</h3>
        <p>
          You agree to use the app only for lawful purposes and in accordance
          with these Terms of Use. You agree not to use the app in any way that
          could damage, disable, overburden, or impair the app or interfere with
          any other party's use and enjoyment of the app.
        </p>
        <h3 class="text-xl font-bold mt-6 mb-2">2. User Accounts</h3>
        <p>
          To access some features of the app, you may be required to create an
          account. You must provide accurate and complete information and keep
          your account information updated. You are responsible for maintaining
          the confidentiality of your account and password and for restricting
          access to your device.
        </p>
        <h3 class="text-xl font-bold mt-6 mb-2">3. Privacy</h3>
        <p>
          Your use of the app is also governed by our Privacy Policy, which
          explains how we collect, use, and disclose information that we obtain
          through your use of the app.
        </p>
        <h3 class="text-xl font-bold mt-6 mb-2">4. Intellectual Property</h3>
        <p>
          The app and its entire contents, features, and functionality are owned
          by us, our licensors, or other providers of such material and are
          protected by intellectual property laws.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfUse",
  methods: {
    goBack() {
      this.$router.go(-1); // Navigates back to the previous route
    },
  },
};
</script>
