<template>
  <div class="p-5 rounded-lg max-w-lg mx-auto mt-10">
    <h1 class="text-gray-800 text-xl font-semibold mb-4">Inbox</h1>
    <ul>
      <li
        v-for="message in messages"
        :key="message.id"
        class="bg-white p-4 rounded mb-2 shadow transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
      >
        <strong>{{ message.sender }}</strong>
        <p class="text-gray-600 mt-1">{{ message.text }}</p>
      </li>
    </ul>
  </div>
  <bottom-navbar />
</template>

<script>
export default {
  data() {
    return {
      messages: [
        { id: 1, sender: "User123", text: "Hello, how are you?" },
        {
          id: 2,
          sender: "User456",
          text: "Reminder about the meeting tomorrow.",
        },
        { id: 3, sender: "User789", text: "Can you send me the file?" },
      ],
    };
  },
};
</script>

<style scoped>
.hover\:shadow-lg:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>
