<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />

    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-7">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Billing details</h3>
              </div>
              <div class="card-body">
                <!-- Checkout Form -->
                <form @submit.prevent="submitForm" ref="checkoutForm">
                  <!-- Error Display Section -->
                  <div
                    v-if="submitted && addressErrors.length"
                    class="alert alert-danger"
                  >
                    <ul>
                      <li v-for="error in addressErrors" :key="error">
                        {{ error }}
                      </li>
                    </ul>
                  </div>

                  <!-- Personal Information -->
                  <div class="info-widget">
                    <h4 class="card-title">Personal Information</h4>
                    <div class="row">
                      <div class="col-md-6 col-sm-12">
                        <div class="mb-3 card-label">
                          <label class="mb-2">First Name</label>
                          <input
                            class="form-control"
                            type="text"
                            name="firstName"
                            v-model="form.firstName"
                            :class="{
                              'is-invalid': submitted && !form.firstName,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="mb-3 card-label">
                          <label class="mb-2">Last Name</label>
                          <input
                            class="form-control"
                            type="text"
                            name="lastName"
                            v-model="form.lastName"
                            :class="{
                              'is-invalid': submitted && !form.lastName,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="mb-3 card-label">
                          <label class="mb-2">Email</label>
                          <input
                            class="form-control"
                            type="email"
                            name="email"
                            v-model="form.email"
                            :class="{ 'is-invalid': submitted && !form.email }"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="mb-3 card-label">
                          <label class="mb-2">Phone</label>
                          <input
                            class="form-control"
                            type="tel"
                            name="phone"
                            v-model="form.phone"
                            :class="{ 'is-invalid': submitted && !form.phone }"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="mb-3 card-label">
                          <label class="mb-2">Street Address</label>
                          <input
                            class="form-control"
                            type="text"
                            name="streetAddress"
                            v-model="form.streetAddress"
                            :class="{
                              'is-invalid': submitted && !form.streetAddress,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="mb-3 card-label">
                          <label class="mb-2">ZIP Code</label>
                          <input
                            class="form-control"
                            type="number"
                            name="zipCode"
                            v-model="form.zipCode"
                            :class="{
                              'is-invalid': submitted && !form.zipCode,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="mb-3 card-label">
                          <label class="mb-2">City</label>
                          <input
                            class="form-control"
                            type="text"
                            name="city"
                            v-model="form.city"
                            :class="{ 'is-invalid': submitted && !form.city }"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="mb-3 card-label">
                          <label class="mb-2">Country</label>
                          <input
                            class="form-control"
                            type="text"
                            name="country"
                            v-model="form.country"
                            :class="{
                              'is-invalid': submitted && !form.country,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!--                    <div class="exist-customer">
                      Existing Customer?
                      <a href="javascript:;">Click here to login</a>
                    </div>-->
                  </div>
                  <!-- /Personal Information -->

                  <!-- Shipping Details -->
                  <div class="info-widget">
                    <h4 class="card-title">Shipping Details</h4>
                    <div class="terms-accept">
                      <div class="custom-checkbox">
                        <input
                          class="me-1"
                          type="checkbox"
                          id="shipToDifferentAddress"
                          v-model="shipToDifferentAddress"
                        />
                        <label for="shipToDifferentAddress"
                          >Ship to a different address?</label
                        >
                      </div>
                    </div>
                    <!-- Conditionally render the shipping address form -->
                    <div v-if="shipToDifferentAddress">
                      <!-- Error Section -->
                      <div
                        v-if="shippingErrors.length"
                        class="alert alert-danger"
                      >
                        <ul>
                          <li v-for="error in shippingErrors" :key="error">
                            {{ error }}
                          </li>
                        </ul>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-sm-12">
                          <div class="mb-3 card-label">
                            <label class="mb-2">First Name</label>
                            <input
                              class="form-control"
                              type="text"
                              name="shipping-firstName"
                              v-model="shipping.firstName"
                              :class="{
                                'is-invalid': submitted && !shipping.firstName,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="mb-3 card-label">
                            <label class="mb-2">Last Name</label>
                            <input
                              class="form-control"
                              type="text"
                              name="shipping-lastName"
                              v-model="shipping.lastName"
                              :class="{
                                'is-invalid': submitted && !shipping.lastName,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="mb-3 card-label">
                            <label class="mb-2">Email</label>
                            <input
                              class="form-control"
                              type="email"
                              name="shipping-email"
                              v-model="shipping.email"
                              :class="{
                                'is-invalid': submitted && !shipping.email,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="mb-3 card-label">
                            <label class="mb-2">Phone</label>
                            <input
                              class="form-control"
                              type="text"
                              name="shipping-phone"
                              v-model="shipping.phone"
                              :class="{
                                'is-invalid': submitted && !shipping.phone,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="mb-3 card-label">
                            <label class="mb-2">Street Address</label>
                            <input
                              class="form-control"
                              type="text"
                              name="shipping-streetAddress"
                              v-model="shipping.streetAddress"
                              :class="{
                                'is-invalid':
                                  submitted && !shipping.streetAddress,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="mb-3 card-label">
                            <label class="mb-2">ZIP Code</label>
                            <input
                              class="form-control"
                              type="number"
                              name="shipping-zipCode"
                              v-model="shipping.zipCode"
                              :class="{
                                'is-invalid': submitted && !shipping.zipCode,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="mb-3 card-label">
                            <label class="mb-2">City</label>
                            <input
                              class="form-control"
                              type="text"
                              name="shipping-city"
                              v-model="shipping.city"
                              :class="{
                                'is-invalid': submitted && !shipping.city,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="mb-3 card-label">
                            <label class="mb-2">Country</label>
                            <input
                              class="form-control"
                              type="text"
                              name="shipping-country"
                              v-model="shipping.country"
                              :class="{
                                'is-invalid': submitted && !shipping.country,
                              }"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End Conditionally render the shipping address form -->

                    <div class="mb-3 card-label">
                      <label class="ps-0 ms-0 mb-2"
                        >Order notes (Optional)</label
                      >
                      <textarea
                        rows="5"
                        class="form-control"
                        name="shipping"
                      ></textarea>
                    </div>
                  </div>
                  <!-- /Shipping Details -->

                  <div class="payment-widget">
                    <h4 class="card-title">Payment Method</h4>

                    <!-- Credit Card Payment -->
                    <div class="payment-list">
                      <label class="payment-radio credit-card-option">
                        <input
                          type="radio"
                          name="paymentMethod"
                          value="credit"
                          v-model="paymentMethod"
                        />
                        <span class="checkmark"></span>
                        Credit card
                      </label>
                      <div v-if="paymentMethod === 'credit'" class="row">
                        <div class="col-md-6">
                          <div class="mb-3 card-label">
                            <label for="card_name">Name on Card</label>
                            <input
                              class="form-control"
                              id="card_name"
                              type="text"
                              v-model="cardDetails.name"
                              :class="{
                                'is-invalid': submitted && !cardDetails.name,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3 card-label">
                            <label for="card_number">Card Number</label>
                            <input
                              class="form-control"
                              id="card_number"
                              placeholder="1234  5678  9876  5432"
                              type="text"
                              v-model="cardDetails.number"
                              :class="{
                                'is-invalid': submitted && !cardDetails.number,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="mb-3 card-label">
                            <label for="expiry_month">Expiry Month</label>
                            <input
                              class="form-control"
                              id="expiry_month"
                              placeholder="MM"
                              type="text"
                              v-model="cardDetails.expiryMonth"
                              :class="{
                                'is-invalid':
                                  submitted && !cardDetails.expiryMonth,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="mb-3 card-label">
                            <label for="expiry_year">Expiry Year</label>
                            <input
                              class="form-control"
                              id="expiry_year"
                              placeholder="YY"
                              type="text"
                              v-model="cardDetails.expiryYear"
                              :class="{
                                'is-invalid':
                                  submitted && !cardDetails.expiryYear,
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="mb-3 card-label">
                            <label for="cvv">CVV</label>
                            <input
                              class="form-control"
                              id="cvv"
                              type="text"
                              v-model="cardDetails.cvv"
                              :class="{
                                'is-invalid': submitted && !cardDetails.cvv,
                              }"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /Credit Card Payment -->

                    <!-- Paypal Payment -->
                    <div class="payment-list">
                      <label class="payment-radio paypal-option">
                        <input
                          type="radio"
                          name="paymentMethod"
                          value="paypal"
                          v-model="paymentMethod"
                        />
                        <span class="checkmark"></span>
                        Paypal
                      </label>
                    </div>
                    <div class="payment-list">
                      <label class="payment-radio paypal-option">
                        <input
                          type="radio"
                          name="paymentMethod"
                          value="invoice"
                          v-model="paymentMethod"
                        />
                        <span class="checkmark"></span>
                        Pay by Invoice
                      </label>
                    </div>
                    <!-- /Paypal Payment -->

                    <!-- Terms Accept -->
                    <div class="terms-accept">
                      <div class="custom-checkbox">
                        <input
                          type="checkbox"
                          v-model="acceptTerms"
                          :class="{ 'is-invalid': submitted && !acceptTerms }"
                        />
                        <label
                          >I have read and accept
                          <a href="#">Terms & Conditions</a></label
                        >
                      </div>
                    </div>
                    <!-- /Terms Accept -->
                    <!-- Error Section -->
                    <div
                      v-if="submitted && validationErrors.length"
                      class="alert alert-danger"
                    >
                      <ul>
                        <li v-for="error in validationErrors" :key="error">
                          {{ error }}
                        </li>
                      </ul>
                    </div>

                    <!-- Submit Section -->
                    <div class="submit-section mt-4">
                      <b-button
                        type="submit"
                        class="btn btn-primary submit-btn"
                      >
                        Confirm and Pay
                      </b-button>
                    </div>
                    <!-- /Submit Section -->
                  </div>
                </form>
                <!-- /Checkout Form -->
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-5 theiaStickySidebar">
            <div class="stickysidebar">
              <!-- Booking Summary -->
              <div class="card booking-card">
                <div class="card-header">
                  <h3 class="card-title">Your Order</h3>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-center mb-0">
                      <tr>
                        <th>Product</th>
                        <th class="text-end">Total</th>
                      </tr>
                      <tbody>
                        <tr
                          v-if="orderPackages.length"
                          v-for="item in orderPackages"
                          :key="item.package_id"
                        >
                          <td>
                            {{ item.name }}
                          </td>
                          <td class="text-end">€{{ item.totalPrice }}</td>
                        </tr>
                        <!--                        <tr>
                          <td>Safi Natural Blood Purifier Syrup 200 ml</td>
                          <td class="text-end">$200</td>
                        </tr>-->
                      </tbody>
                    </table>
                  </div>
                  <div class="booking-summary pt-5">
                    <div class="booking-item-wrap">
                      <ul class="booking-date d-block pb-0">
                        <li>
                          Subtotal <span>€{{ totalPrice.toFixed(2) }}</span>
                        </li>
                        <li>Shipping <span>€0.00</span></li>
                      </ul>
                      <ul class="booking-fee">
                        <li>Tax <span>€0.00</span></li>
                      </ul>
                      <div class="booking-total">
                        <ul class="booking-total-list">
                          <li>
                            <span>Total</span>
                            <span class="total-cost"
                              >€{{ totalPrice.toFixed(2) }}</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Booking Summary -->
          </div>
        </div>
      </div>
    </div>
    <indexfooter></indexfooter>
    <bottom-navbar />
  </div>
</template>

<script setup>
import { useCart } from "@/composables/useCart";
import { ref, onMounted } from "vue";
import { getOrders } from "@/services/order";
import { useRouter } from "vue-router";

const router = useRouter();

const title = ref("Checkout");
const text = ref("Home");
const text1 = ref("Checkout");

const checkoutForm = ref(null);

const currentOrder = ref(null);
const orderPackages = ref([]);
// Adderess
const form = ref({
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  streetAddress: "",
  zipCode: "",
  city: "",
  country: "",
});

const addressErrors = ref([]);
const shippingErrors = ref([]);

const shipToDifferentAddress = ref(false);
// Different Address
const shipping = ref({
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  streetAddress: "",
  zipCode: "",
  city: "",
  country: "",
});

// Payment method
const paymentMethod = ref("credit"); // Default payment method
const cardDetails = ref({
  name: "",
  number: "",
  expiryMonth: "",
  expiryYear: "",
  cvv: "",
});
const acceptTerms = ref(false);
const submitted = ref(false);
const validationErrors = ref([]);

const { totalPrice, fetchCart } = useCart();

onMounted(async () => {
  // todo: refactor this section
  await fetchCart();
  await fetchOrders();
});

async function fetchOrders() {
  // Fetch orders
  const response = await getOrders(1, 10); // Get paginated orders (assuming the first page returns active orders)
  const orders = response.data.data;
  // console.log('orders:', orders[0].packages)
  // Find the new order
  const newOrder = orders.find((order) => order.status === "new");
  if (newOrder) {
    currentOrder.value = newOrder;

    // Populate the orderPackages array with the package data
    orderPackages.value = newOrder.packages.map((packageItem) => ({
      package_id: packageItem.package_id,
      name: packageItem.item_name,
      image: packageItem.image,
      price: packageItem.package_price,
      quantity: packageItem.count,
      totalPrice: packageItem.total_price,
    }));
    console.log("currentOrder.value:", currentOrder.value);
    console.log("orderPackages.value:", orderPackages.value[0]);
    currentOrder.value.packages_sum_price = newOrder.packages_sum_price;
  }
}
function validateBillingFields() {
  addressErrors.value = [];

  // Validate firstName
  if (!form.value.firstName)
    addressErrors.value.push("First Name is required.");

  // Validate lastName
  if (!form.value.lastName) addressErrors.value.push("Last Name is required.");

  // Validate email
  if (!form.value.email) {
    addressErrors.value.push("Email is required.");
  } else if (!/\S+@\S+\.\S+/.test(form.value.email)) {
    addressErrors.value.push("Email is invalid.");
  }

  // const phonePattern = /^\+?\d{10,15}$/;

  // Validate phone
  if (!form.value.phone) {
    addressErrors.value.push("Phone number is required.");
  } else {
    const phoneNumber = form.value.phone.trim(); // Trim any extra spaces

    // Allow for optional "+" at the beginning, and then 10 to 15 digits, with optional spaces or dashes between digits
    // const phonePattern = /^\+?\d{1,4}?[\d\s-]{10,15}$/;
    const phonePattern = /^\+?\d{10,15}$/;

    if (!phonePattern.test(phoneNumber)) {
      addressErrors.value.push("Phone number is invalid.");
    }
  }

  // Validate streetAddress
  if (!form.value.streetAddress)
    addressErrors.value.push("Street Address is required.");

  // Validate zipCode
  if (!form.value.zipCode) {
    addressErrors.value.push("ZIP Code is required.");
  } else {
    // Alphanumeric ZIP code, allowing optional spaces or dashes
    const zipCodePattern = /^[A-Za-z0-9\s-]{4,10}$/;

    if (!zipCodePattern.test(form.value.zipCode)) {
      addressErrors.value.push("ZIP Code is invalid.");
    }
  }

  // Validate city
  if (!form.value.city) addressErrors.value.push("City is required.");

  // Validate country
  if (!form.value.country) addressErrors.value.push("Country is required.");

  return addressErrors.value.length === 0;
}

function validateShippingFields() {
  shippingErrors.value = [];

  // Validate firstName
  if (!shipping.value.firstName)
    shippingErrors.value.push("First Name is required.");

  // Validate lastName
  if (!shipping.value.lastName)
    shippingErrors.value.push("Last Name is required.");

  // Validate email
  if (!shipping.value.email) {
    shippingErrors.value.push("Email is required.");
  } else if (!/\S+@\S+\.\S+/.test(shipping.value.email)) {
    shippingErrors.value.push("Email is invalid.");
  }

  // Validate phone
  if (!shipping.value.phone) {
    shippingErrors.value.push("Phone number is required.");
  } else {
    const phoneNumber = shipping.value.phone.trim(); // Trim any extra spaces

    // Allow for optional "+" at the beginning, and then 10 to 15 digits, with optional spaces or dashes between digits
    // const phonePattern = /^\+?\d{1,4}?[\d\s-]{10,15}$/;
    const phonePattern = /^\+?\d{10,15}$/;

    if (!phonePattern.test(phoneNumber)) {
      shippingErrors.value.push("Phone number is invalid.");
    }
  }

  // Validate streetAddress
  if (!shipping.value.streetAddress)
    shippingErrors.value.push("Street Address is required.");

  // Validate zipCode
  if (!shipping.value.zipCode) {
    shippingErrors.value.push("ZIP Code is required.");
  } else {
    // Alphanumeric ZIP code, allowing optional spaces or dashes
    const zipCodePattern = /^[A-Za-z0-9\s-]{4,10}$/;

    if (!zipCodePattern.test(shipping.value.zipCode)) {
      shippingErrors.value.push("ZIP Code is invalid.");
    }
  }

  // Validate city
  if (!shipping.value.city) shippingErrors.value.push("City is required.");

  // Validate country
  if (!shipping.value.country)
    shippingErrors.value.push("Country is required.");

  return shippingErrors.value.length === 0;
}

function validatePaymentFields() {
  validationErrors.value = [];

  if (paymentMethod.value === "credit") {
    if (!cardDetails.value.name)
      validationErrors.value.push("Name on Card is required.");
    if (!cardDetails.value.number)
      validationErrors.value.push("Card Number is required.");
    if (!cardDetails.value.expiryMonth)
      validationErrors.value.push("Expiry Month is required.");
    if (!cardDetails.value.expiryYear)
      validationErrors.value.push("Expiry Year is required.");
    if (!cardDetails.value.cvv) validationErrors.value.push("CVV is required.");
  }

  if (!acceptTerms.value) {
    validationErrors.value.push("You must accept the terms and conditions.");
  }

  return validationErrors.value.length === 0;
}

async function submitForm() {
  console.log("submitForm called");

  try {
    if (!currentOrder.value || currentOrder.value.status !== "new") {
      alert("No active order available for checkout.");
      return;
    }
    // errors.value = {};
    // shippingErrors.value = {};
    submitted.value = true;
    console.log("currentOrder.value.order_id", currentOrder.value.order_id);

    localStorage.setItem("NewOrder", JSON.stringify(currentOrder.value));

    if (
      (validateBillingFields() || validateShippingFields()) &&
      validatePaymentFields()
    ) {
      console.log("Form is valid, proceeding with submission");
      router.push("/payment-preview");
    }
  } catch (error) {
    console.error("Error during checkout:", error);
    alert("An error occurred. Please check the console for details.");
  }
}
</script>
