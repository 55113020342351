<template>
  <div class="p-4">
    <h1 class="text-2xl font-semibold mb-4">Category: {{ category.name }}</h1>
    <!-- Loading spinner -->
    <div v-if="isLoading" class="flex justify-center items-center h-[30rem]">
      <i class="fa fa-spinner fa-spin text-4xl text-gray-800"></i>
    </div>

    <!-- Posts List -->
    <div v-if="posts.length" class="space-y-2">
      <ul class="divide-x-2 divide-gray-300">
        <li v-for="post in posts" :key="post.id" class="py-2 flex items-center">
          <router-link
            :to="{ name: 'PostDetail', params: { postId: post.id } }"
            class="flex divide-y mt-3 divide-gray-300 items-center justify-between w-full text-blue-600 hover:text-blue-800"
          >
            <div class="flex items-center justify-between space-x-4">
              <img
                :src="post.image"
                alt="{{ post.name }}"
                class="w-12 h-12 rounded-full object-cover"
              />
              <h3 class="flex-1 text-gray-800">{{ post.name }}</h3>
            </div>
            <div class="">
              <i class="fas fa-chevron-right block text-gray-500"></i>
            </div>
          </router-link>
        </li>
      </ul>
    </div>

    <!-- No data message -->
    <div v-else-if="!isLoading" class="text-center text-gray-700">
      No posts available at this moment.
    </div>
  </div>
</template>

<script>
import { postCategoryDetails } from "@/services/posts";

export default {
  props: {
    categoryName: String,
  },
  data() {
    return {
      isLoading: true,
      posts: [],
      category: {},
    };
  },
  created() {
    this.fetchPosts();
  },
  methods: {
    async fetchPosts() {
      try {
        const response = await postCategoryDetails(this.categoryName);
        console.log(response, "checkkkkkk");
        this.posts = response.data;
        this.category = response.category;
      } catch (error) {
        console.error("Failed to fetch posts:", error);
      } finally {
        this.isLoading = false; // Set loading to false regardless of outcome
      }
    },
  },
};
</script>

<style scoped>
/* If you still want to apply some custom styles */
</style>
