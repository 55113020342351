<template>
  <h3 class="text-xl font-semibold mb-6"><i>Diagnoses</i></h3>

  <!-- Loading spinner -->
  <div v-if="isLoading" class="flex justify-center items-center h-[30rem]">
    <i class="fa fa-spinner fa-spin text-4xl text-gray-800"></i>
  </div>

  <!-- Error message -->
  <div v-if="errorMessage" class="text-red-500 text-center">
    {{ errorMessage }}
  </div>

  <!-- Diagnoses list -->
  <div
    v-if="!isLoading && !errorMessage && diagnoses.length"
    class="grid grid-cols-3 gap-4"
  >
    <router-link
      v-for="(diagnosis, index) in diagnoses"
      :key="diagnosis.id"
      :to="{ name: 'PatientsDiagnosisItem', params: { id: diagnosis.id } }"
      class="text-center cursor-pointer justify-center items-center"
    >
      <div class="">
        <img
          :src="require(`@/assets/img/bg/tests/${index + 1}.png`)"
          alt="diagnosis"
          class="w-[5rem] object-fill rounded-full mx-auto max-h-[5rem]"
        />
      </div>
      <div class="text-gray-700 font-semibold mt-2">{{ diagnosis.name }}</div>
      <div class="text-gray-500">{{ diagnosis.date }}</div>
    </router-link>
  </div>

  <!-- No data message -->
  <div v-if="!diagnoses.length && !isLoading" class="text-center text-gray-700">
    No diagnoses available at this moment.
  </div>

  <!-- List of doctors -->
  <div v-if="doctors.length" class="mt-16">
    <h3 class="text-xl font-semibold mb-4">
      <i>Your Contact Person</i>
    </h3>
    <contact-person
      v-for="doctor in doctors"
      :key="doctor.id"
      :doctor="doctor"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ContactPerson from "./contact-person.vue";

export default {
  name: "YourDiagnoses",
  components: {
    ContactPerson,
  },
  data() {
    return {
      errorMessage: "",
    };
  },
  computed: {
    ...mapState("tests", ["tests", "isLoading", "errorMessage"]),
    diagnoses() {
      return this.tests.categories || [];
    },
    doctors() {
      return this.tests.doctors || [];
    },
  },
  mounted() {
    this.fetchDataIfNeeded();
  },
  methods: {
    ...mapActions("tests", ["fetchTests"]),
    async fetchDataIfNeeded() {
      if (!this.diagnoses.length && !this.isLoading) {
        try {
          await this.fetchTests();
        } catch (error) {
          this.errorMessage =
            "Error fetching diagnoses. Please try again later.";
          console.error("Error fetching diagnoses:", error);
        }
      }
    },
  },
};
</script>

<style scoped>
.p-4 {
  padding: 1rem;
}
.bg-gray-100 {
  background-color: #f7fafc;
}
.grid {
  display: grid;
}
.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}
.gap-4 {
  gap: 1rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.bg-white {
  background-color: #fff;
}
.shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.text-gray-700 {
  color: #4a5568;
}
.text-gray-500 {
  color: #a0aec0;
}
.text-center {
  text-align: center;
}
</style>
