<template>
  <div class="test-value-details">
    <!-- Back button -->
    <button @click="goBack" class="back-arrow">
      <i class="fa-solid fa-chevron-left"></i>
    </button>

    <!-- Loading Spinner -->
    <div v-if="isLoading" class="loading-spinner">
      <i class="fa-solid fa-spinner fa-spin"></i> Loading...
    </div>

    <!-- Content Section -->
    <div v-else-if="parameterData && parameterData.values">
      <h1>{{ parameterData.name }}</h1>

      <!-- Single Progress Bar showing all ranges -->
      <div class="progress-container my-6 relative">
        <!-- Full Range (reference_lower_men to reference_upper_men) -->
        <div class="bg-gray-200 h-6 rounded-lg relative">
          <!-- Good Range (blue) -->
          <div
            class="absolute top-0 h-6 bg-blue-500 rounded-lg"
            :style="{
              left: goodRangeStart + '%',
              width: goodRangeWidth + '%',
            }"
          ></div>

          <!-- Optimal Range (green) -->
          <div
            class="absolute top-0 h-6 bg-green-500 rounded-lg opacity-80"
            :style="{
              left: optimalRangeStart + '%',
              width: optimalRangeWidth + '%',
            }"
          ></div>

          <!-- User Value Marker (red) -->
          <div
            class="absolute top-0 h-6 w-1 bg-red-500"
            :style="{
              left: userMarkerPosition + '%',
            }"
          ></div>

          <!-- Start and End Labels -->
          <span
            class="absolute text-xs"
            :style="{
              left: goodRangeStart + '%',
            }"
            >{{ reference_lower_good_men }}</span
          >
          <span
            class="absolute text-xs"
            :style="{
              left: goodRangeStart + goodRangeWidth - 5 + '%',
            }"
            >{{ reference_upper_good_men }}</span
          >

          <span
            class="absolute text-xs"
            :style="{
              left: optimalRangeStart + '%',
            }"
            >{{ reference_lower_optimal_men }}</span
          >
          <span
            class="absolute text-xs"
            :style="{
              left: optimalRangeStart + optimalRangeWidth - 5 + '%',
            }"
            >{{ reference_upper_optimal_men }}</span
          >

          <!-- User Value Label -->
          <span
            class="absolute text-xs bg-white px-1"
            :style="{
              left: userMarkerPosition - 1 + '%',
            }"
            >{{ userValue }}</span
          >
        </div>

        <!-- Progress Labels for Full Range -->
        <div class="flex justify-between mt-2 text-sm">
          <span>{{ reference_lower_men }}</span>
          <span>{{ reference_upper_men }}</span>
        </div>
      </div>

      <h2>What is {{ parameterData.name }}?</h2>
      <p class="my-4">{{ parameterData.description }}</p>

      <!-- Include DotChart for visualizing data -->
      <DotChart :values="parameterData.values" />

      <!-- User Value Card -->
      <div class="mt-6">
        <div class="bg-white shadow-md rounded-lg p-4">
          <h3 class="text-lg font-bold">User's Value</h3>
          <div class="mt-2">
            <p>
              <strong>Value:</strong> {{ parameterData.values[0].value }}
              {{ parameterData.values[0].unit }}
            </p>
            <p>
              <strong>Date:</strong>
              {{
                new Date(
                  parameterData.values[0].created_at
                ).toLocaleDateString()
              }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Error Handling -->
    <div v-else>
      <p class="text-red-500">No data available for this parameter.</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DotChart from "@/components/charts/dot-chart.vue";

export default {
  components: {
    DotChart,
  },
  props: {
    id: String, // The ID of the parameter passed as a prop
  },
  computed: {
    ...mapState("tests", {
      parameters: (state) => state.parameters, // Access the parameters state
      isLoading: (state) => state.isLoading, // Loading state
    }),
    parameterData() {
      // Dynamically get parameter data by the passed id
      return this.parameters[this.id] || {};
    },
    reference_lower_men() {
      return this.parameterData?.reference_lower_men || 20;
    },
    reference_upper_men() {
      return this.parameterData?.reference_upper_men || 140;
    },
    reference_lower_good_men() {
      return this.parameterData?.reference_lower_good_men || 40;
    },
    reference_upper_good_men() {
      return this.parameterData?.reference_upper_good_men || 100;
    },
    reference_lower_optimal_men() {
      return this.parameterData?.reference_lower_optimal_men || 60;
    },
    reference_upper_optimal_men() {
      return this.parameterData?.reference_upper_optimal_men || 80;
    },
    userValue() {
      return this.parameterData?.values?.[0]?.value || 80;
    },
    goodRangeStart() {
      return (
        ((this.reference_lower_good_men - this.reference_lower_men) /
          (this.reference_upper_men - this.reference_lower_men)) *
        100
      );
    },
    goodRangeWidth() {
      return (
        ((this.reference_upper_good_men - this.reference_lower_good_men) /
          (this.reference_upper_men - this.reference_lower_men)) *
        100
      );
    },
    optimalRangeStart() {
      return (
        ((this.reference_lower_optimal_men - this.reference_lower_men) /
          (this.reference_upper_men - this.reference_lower_men)) *
        100
      );
    },
    optimalRangeWidth() {
      return (
        ((this.reference_upper_optimal_men - this.reference_lower_optimal_men) /
          (this.reference_upper_men - this.reference_lower_men)) *
        100
      );
    },
    userMarkerPosition() {
      return (
        ((this.userValue - this.reference_lower_men) /
          (this.reference_upper_men - this.reference_lower_men)) *
        100
      );
    },
  },
  mounted() {
    this.fetchParameterDetails(this.id); // Fetch parameter details when the component mounts
  },
  methods: {
    ...mapActions("tests", ["fetchParameterDetails"]), // Map the action to fetch parameter details
    goBack() {
      this.$router.go(-1); // Go back to the previous route
    },
  },
};
</script>

<style scoped>
.test-value-details {
  padding: 20px;
  max-width: 600px;
  margin: auto;
  font-family: Arial, sans-serif;
}

.back-arrow {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  color: #52c41a;
}

.loading-spinner i {
  margin-right: 8px;
}

.progress-container {
  position: relative;
  width: 100%;
  max-width: 600px;
}

.bg-blue-500 {
  background-color: black;
}

.bg-green-500 {
  background-color: #38c172;
}

.bg-red-500 {
  background-color: #e3342f;
}
</style>
