import { createStore } from "vuex";
import plans from "./modules/plans";
import authentication from "./modules/authentication";
import tests from "./modules/tests";

const store = createStore({
  modules: {
    plans,
    authentication,
    tests,
  },
  state() {
    return {
      registrationMessage: "",
      userEmail: "",
      registeredUser: null,
      isAuthenticated: false, // Track authentication status
      authToken: "", // Store authentication token (e.g., JWT)
      successMessage: "",
      errorMessage: "",
    };
  },
  mutations: {
    setRegistrationMessage(state, message) {
      state.registrationMessage = message;
    },
    setUserEmail(state, email) {
      state.userEmail = email;
    },
    setRegisteredUser(state, userData) {
      state.registeredUser = userData;
    },
    setIsAuthenticated(state, status) {
      state.isAuthenticated = status;
    },
    setAuthToken(state, token) {
      state.authToken = token;
    },
    setSuccessMessage(state, message) {
      state.successMessage = message;
    },
    setErrorMessage(state, message) {
      state.errorMessage = message;
    },
    clearErrorMessage(state) {
      state.errorMessage = "";
    },
  },
  getters: {
    registrationMessage: (state) => state.registrationMessage,
    userEmail: (state) => state.userEmail,
    registeredUser: (state) => state.registeredUser,
    isAuthenticated: (state) => state.isAuthenticated,
    authToken: (state) => state.authToken,
    successMessage: (state) => state.successMessage,
    errorMessage: (state) => state.errorMessage,
  },
  actions: {
    setRegistrationMessage({ commit }, message) {
      commit("setRegistrationMessage", message);
    },
    setUserEmail({ commit }, email) {
      commit("setUserEmail", email);
    },
    setRegisteredUser({ commit }, userData) {
      commit("setRegisteredUser", userData);
    },
    setIsAuthenticated({ commit }, status) {
      commit("setIsAuthenticated", status);
    },
    setAuthToken({ commit }, token) {
      commit("setAuthToken", token);
    },
    setSuccessMessage({ commit }, message) {
      commit("setSuccessMessage", message);
    },
    setErrorMessage({ commit }, message) {
      commit("setErrorMessage", message);
    },
    clearErrorMessage({ commit }) {
      commit("clearErrorMessage");
    },
  },
});

export default store;
