<template>
  <ul class="main-nav">
    <li :class="homeMenu ? 'active' : 'notactive'">
      <router-link to="/home">Home</router-link>
    </li>

    <li
      class="searchbar"
      :style="isHomeSeven2Route ? 'display: none;' : 'display: block;'"
    >
      <a href="javascript:void(0);" @click="toggleElement"
        ><i class="feather-search"></i
      ></a>
      <div
        class="togglesearch"
        v-if="isVisible"
        @click="handleClick"
        style="display: block"
      >
        <form @submit.prevent="submitForm">
          <div class="input-group">
            <input type="text" class="form-control" />
            <button type="submit" class="btn">Search</button>
          </div>
        </form>
      </div>
    </li>
    <li class="login-link">
      <router-link to="/login">Login / Signup</router-link>
    </li>
    <li class="register-btn" v-if="isHomeOneRoute">
      <router-link to="/register" class="btn reg-btn"
        ><i class="feather-user"></i>Register</router-link
      >
    </li>
    <li class="register-btn" v-if="isHomeOne1Route">
      <router-link to="/login" class="btn btn-primary log-btn"
        ><i class="feather-lock"></i>Login</router-link
      >
    </li>
  </ul>
</template>
<script>
export default {
  data() {
    return {
      /*  BalloBock */
      isVisibleBallo: false,
      /*  /BalloBock */

      isVisible: false,
      isVisibleerror: false,
    };
  },
  computed: {
    isHomeOneRoute() {
      return (
        this.$route.path === "/" ||
        this.$route.path === "/home" ||
        this.$route.path === "/about-us" ||
        this.$route.path === "/blank-page" ||
        this.$route.path === "/blog-details" ||
        this.$route.path === "/blog-grid" ||
        this.$route.path === "/blog-list" ||
        this.$route.path === "/coming-soon" ||
        this.$route.path === "/components" ||
        this.$route.path === "/contact-us" ||
        this.$route.path === "/doctor-signup" ||
        this.$route.path === "/error-404" ||
        this.$route.path === "/error-500" ||
        this.$route.path === "/faq" ||
        this.$route.path === "/forgot-password" ||
        this.$route.path === "/forgot-password2" ||
        this.$route.path === "/login-email-otp" ||
        this.$route.path === "/login-email" ||
        this.$route.path === "/login-phone-otp" ||
        this.$route.path === "/login" ||
        this.$route.path === "/mobile-otp" ||
        this.$route.path === "/patient-signup" ||
        this.$route.path === "/register" ||
        this.$route.path === "/search-2" ||
        this.$route.path === "/search" ||
        this.$route.path === "/signup-success" ||
        this.$route.path === "/terms-condition" ||
        this.$route.path === "/email-otp" ||
        this.$route.path === "/login-phone"
      );
    },
    isHomeOne1Route() {
      return (
        this.$route.path === "/" ||
        this.$route.path === "/home" ||
        this.$route.path === "/about-us" ||
        this.$route.path === "/blank-page" ||
        this.$route.path === "/blog-details" ||
        this.$route.path === "/blog-grid" ||
        this.$route.path === "/blog-list" ||
        this.$route.path === "/coming-soon" ||
        this.$route.path === "/components" ||
        this.$route.path === "/contact-us" ||
        this.$route.path === "/doctor-register" ||
        this.$route.path === "/doctor-search-grid" ||
        this.$route.path === "/doctor-signup" ||
        this.$route.path === "/error-404" ||
        this.$route.path === "/error-500" ||
        this.$route.path === "/faq" ||
        this.$route.path === "/forgot-password" ||
        this.$route.path === "/forgot-password2" ||
        this.$route.path === "/login-email-otp" ||
        this.$route.path === "/login-email" ||
        this.$route.path === "/login-phone-otp" ||
        this.$route.path === "/login" ||
        this.$route.path === "/maintenance" ||
        this.$route.path === "/map-grid" ||
        this.$route.path === "/map-list" ||
        this.$route.path === "/mobile-otp" ||
        this.$route.path === "/patient-signup" ||
        this.$route.path === "/pricing" ||
        this.$route.path === "/privacy-policy" ||
        this.$route.path === "/register" ||
        this.$route.path === "/search-2" ||
        this.$route.path === "/search" ||
        this.$route.path === "/signup-success" ||
        this.$route.path === "/terms-condition" ||
        this.$route.path === "/email-otp" ||
        this.$route.path === "/login-phone"
      );
    },
    currentPath() {
      return this.$route.name;
    },
    homeMenu() {
      return this.$route.name == "index";
    },
  },
  methods: {
    submitForm() {
      this.$router.push("/search");
    },
    toggleElement() {
      this.isVisible = !this.isVisible;
    },
    toggleVisibilityBallo() {
      this.isVisibleBallo = !this.isVisibleBallo;
    },
    toggleVisibilitysearch() {
      this.isVisiblepatientssearch = !this.isVisiblepatientssearch;
    },
    toggleVisibilityauthentication() {
      this.isVisibleauthentication = !this.isVisibleauthentication;
    },
    toggleVisibilityerror() {
      this.isVisibleerror = !this.isVisibleerror;
    },
    redirectReloadsearch() {
      this.$router.push({ path: "/search-2" }).then(() => {
        this.$router.go();
      });
    },
  },
};
</script>
