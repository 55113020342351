<template>
  <nav
    class="fixed inset-x-0 bottom-0 bg-white !z-50 border-t border-gray-300 shadow"
  >
    <ul class="flex justify-around text-xs">
      <li
        v-for="link in links"
        :key="link.name"
        :class="[
          'flex relative flex-col items-center py-3',
          { 'border-t-4 border-black': isActive(link.to) },
        ]"
      >
        <router-link :to="link.to" class="texc w-full">
          <hr
            class="border-2 absolute top-0 -left-1 w-10 border-black"
            v-if="isActive(link.to)"
          />
          <i
            :class="[
              'icon z-10 m-0 text-[#626262]',
              link.icon,
              isActive(link.to)
                ? 'text-xl border-solid !text-black'
                : 'text-lg',
            ]"
          ></i>
          <small class="text-center block items-center w-full mx-auto">{{
            link.label
          }}</small>
        </router-link>
        <span
          v-if="link.name === 'cart' && totalItems > 0"
          class="absolute top-[7px] -z-10 -right-3 text-xs rounded-full bg-black text-white px-2"
        >
          {{ totalItems }}
        </span>
      </li>
    </ul>
    <mobile-menu-modal :isOpen="isMenuOpen" @close="closeMenu" />
  </nav>
</template>

<script>
import { ref, onMounted } from "vue";
import { useCart } from "@/composables/useCart";
import { useRoute } from "vue-router";

export default {
  setup() {
    const isMenuOpen = ref(false);
    const { totalItems, fetchCart } = useCart();
    const route = useRoute();

    onMounted(async () => {
      await fetchCart();
    });

    const openMenu = () => {
      isMenuOpen.value = true;
    };

    const closeMenu = () => {
      isMenuOpen.value = false;
    };

    const links = [
      { name: "home", to: "/home", icon: "fa fa-home", label: "Home" },
      { name: "search", to: "/search", icon: "fa fa-search", label: "Search" },
      {
        name: "plans",
        to: { name: "patient-plans" },
        icon: "fa fa-clipboard-check",
        label: "Plans",
      },
      {
        name: "cart",
        to: "/cart",
        icon: "fa fa-cart-shopping",
        label: "Cart",
      },
      {
        name: "profile",
        to: "/profile-settings",
        icon: "far fa-user",
        label: "Profile",
      },
    ];

    const isActive = (path) => {
      if (typeof path === "string") {
        return route.path === path;
      } else {
        return route.name === path.name;
      }
    };

    return {
      isMenuOpen,
      openMenu,
      closeMenu,
      totalItems,
      links,
      isActive,
    };
  },
};
</script>

<style scoped>
.icon {
  display: block;
  text-align: center;
}
</style>
