import axiosInstance from "../constants/axios";
import store from "@/store";

/* ******************************************************* REGISTER  **************************************************************** */
// Register a new user
export const registerUser = async (userData) => {
  try {
    const response = await axiosInstance.post("api/register", userData);

    // Save token if returned
    if (response.data.token) {
      localStorage.setItem("token", response.data.token);
    }

    return response.data; // Return the response data to use in the component
  } catch (error) {
    // Handle Axios-specific errors
    if (error.response) {
      // Server responded with a status other than 2xx
      if (error.response.data && error.response.data.message) {
        throw new Error(JSON.stringify(error.response.data.message));
      }
      throw new Error("Server responded with an error.");
    } else if (error.request) {
      // No response was received
      throw new Error("Network error: No response from server.");
    } else {
      // Something happened in setting up the request
      throw new Error("Error in request setup.");
    }
  }
};

// Finalize user registration
export const finalizeRegistration = async (userData) => {
  try {
    const response = await axiosInstance.post("api/change-password", userData);
    return response.data;
  } catch (error) {
    /*catch (error) {
        // Handle Axios-specific errors
        if (error.response) {
            console.log('error logic', error.response)
            // Server responded with a status other than 2xx
            if (error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Server responded with an error.');
        } else if (error.request) {
            // No response was received
            throw new Error('Network error: No response from server.');
        } else {
            // Something happened in setting up the request
            throw new Error('Error in request setup.');
        }
    }*/
    // Extract error message and ensure it is a string
    let errorMessage = "An unknown error occurred.";

    if (error.response) {
      console.log("Error Response:", error.response); // Log entire response
      console.log("Error Response Data:", error.response.data); // Log response data

      if (error.response.data) {
        const serverError = error.response.data.message;
        console.log(
          'typeof serverError === "object"',
          typeof serverError === "object"
        );
        // Ensure serverError is an object and properly handle it
        if (typeof serverError === "object") {
          const errorMessages = [];
          for (const [messages] of Object.entries(serverError)) {
            if (Array.isArray(messages)) {
              messages.forEach((msg) => errorMessages.push(`${msg}`));
            } else {
              errorMessages.push(`${messages}`);
            }
          }
          errorMessage = errorMessages.join(", ");
        } else {
          errorMessage = serverError || "An unknown error occurred.";
        }
      }
    }

    console.error("Formatted Error Message:", errorMessage);

    throw new Error(errorMessage);
  }
};

// Verify registration code
export const verifyRegistrationCode = async (data) => {
  // const { registrationCode, email } = data;

  try {
    const response = await axiosInstance.post("api/change-password ", data);

    return response.data; // todo:Assuming the backend responds with necessary data
  } catch (error) {
    console.error("Error verifying registration code:", error);
    throw error; // todo: Forward the error to be handled in the component
  }
};

/* ******************************************************* LOGIN  **************************************************************** */

// Login user
export const loginUser = async (credentials) => {
  try {
    const response = await axiosInstance.post("api/login", credentials);

    // Extract data from response
    const userData = response.data.data;
    const token = userData.token;

    // Check if token exists and save it
    if (token) {
      localStorage.setItem("token", token);
      store.commit("setIsAuthenticated", true);
      store.commit("setAuthToken", token);
    } else {
      throw new Error("Login failed.. Please try again.");
    }
    return { token, userData };
  } catch (error) {
    let errorMessage = "";
    console.error(
      "Error logging in:",
      error.response ? error.response.data.message[0] : error.message[0]
    );
    if (error.response?.data?.message.email) {
      errorMessage = error.response?.data?.message.email[0];
    } else if (error.response?.data?.message[0]) {
      errorMessage = error.response?.data?.message[0];
    } else if (error.message[0]) {
      errorMessage = error.message[0];
    } else if (error.message.email) {
      errorMessage = error.message.email;
    } else {
      errorMessage = "Login failed. Please try again.";
    }

    throw new Error(errorMessage);
  }
};

// Verify 2FA code
export const verify2FACode = async (code) => {
  try {
    const response = await axiosInstance.post("api/auth/verify-2fa", { code });
    return response.data;
  } catch (error) {
    console.error("Error verifying 2FA code:", error);
    throw error;
  }
};

// forgot password
export const requestPasswordReset = async (email) => {
  try {
    const response = await axiosInstance.post("api/auth/forgot-password", {
      email,
    });
    return response.data;
  } catch (error) {
    console.error("Error requesting password reset:", error);
    throw error;
  }
};

// Reset password
export const resetPassword = async ({ token, password }) => {
  try {
    const response = await axiosInstance.post("api/auth/reset-password", {
      token,
      password,
    });
    return response.data;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

// Verify password
export const verifyPassword = async ({ token, password }) => {
  try {
    const response = await axiosInstance.post("api/verify-password", {
      token,
      password,
    });
    return response.data;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

/* ******************************************************* LOGOUT  **************************************************************** */

// Logout user
export const logoutUser = async () => {
  try {
    // const response = await axiosInstance.post('/logout');
    // console.log('response: ', response)
    // Clear token from local storage
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
      store.commit("setIsAuthenticated", false);
      store.commit("setAuthToken", "");
      store.commit("setRegisteredUser", null);
      localStorage.clear();
    }
  } catch (error) {
    console.error("Error logging out:", error);
    throw error;
  }
};
