<template>
  <div v-if="isActive" class="tab-pane fade show active">
    <!-- Plans List -->
    <div v-for="item in plans" :key="item.item_id" class="">
      <ul>
        <li>
          <div
            class="patinet-information bg-[white] m-2 !mb-4 p-4 rounded-md shadow-md flex items-center"
          >
            <router-link v-if="routerLinkTo" :to="routerLinkTo">
              <img :src="item.image" class="!w-20 h-20" alt="Plan Image" />
            </router-link>

            <div class="flex items-center justify-center">
              <ul class="flex items-center flex-col justify-center">
                <li
                  class="flex !mt-3 !flex-col items-center justify-center m-4"
                >
                  <h4
                    class="m-auto flex !mt-4 flex-col items-center justify-center"
                  >
                    <router-link v-if="routerLinkTo" :to="routerLinkTo">
                      {{ item.name }}
                    </router-link>
                  </h4>
                </li>
                <li>
                  <span class="badge new-tag"
                    >{{ item.custom_dosage }} -
                    {{ item.custom_instructions }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- /Plans List -->
    <!-- Handle no plans case -->
    <div class="m-3" v-if="plans.length === 0">
      <p>No Items available for this time.</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    plans: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      routerLinkTo: null,
    };
  },
  watch: {
    // Watch for changes in `plans`
    plans: {
      handler(newPlans) {
        // Find the first plan with an item_id, if available
        const currentPlan = newPlans.find((plan) => plan.item_id);
        if (currentPlan && currentPlan.item_id) {
          this.routerLinkTo = {
            name: "patient-morning-plan",
            params: { id: currentPlan.item_id },
          };
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style scoped>
.patient-info ul li {
  text-transform: none !important; /* This will override the uppercase styling */
}
</style>
