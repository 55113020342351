<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="main-wrapper p-4">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />
    <loading-spinner v-if="isLoading" />
    <div class="content doctor-content">
      <div class="container">
        <div class="row">
          <!-- Profile Sidebar -->
          <div class="col-lg-4 col-xl-3 theiaStickySidebar">
            <profilesidebar></profilesidebar>
          </div>
          <!-- / Profile Sidebar -->

          <div class="col-lg-8 col-xl-9 mb-5">
            <div class="dashboard-header">
              <h3>
                <i>Shopping Cart</i>
              </h3>
              <ul class="">
                <li>
                  <div class="input-block w-full dash-search-input">
                    <input
                      type="text"
                      class="form-control w-full"
                      placeholder="Search"
                    />
                    <span class="search-icon">
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </span>
                  </div>
                </li>
              </ul>
            </div>

            <!-- Cart Items -->
            <div
              v-if="cart.length"
              class="bg-[white] rounded-sm shadow-md p-4"
              v-for="item in cart"
              :key="item.id"
            >
              <div class="flex gap-3 justify-between relative">
                <div class="table-action absolute">
                  <a
                    href="javascript:void(0);"
                    @click="removeItemFromCart(item.package_id)"
                    class="btn btn-sm bg-danger-light"
                  >
                    <i class="fas fa-times"></i>
                  </a>
                </div>
                <router-link to="/patient-profile" class="w-full">
                  <img
                    :src="item.image"
                    alt="Product Image"
                    class="w-28 h-28 object-cover"
                  />
                  <!--                    <img src="@/assets/img/supplements/supplement-1.jpg" alt="Product Image" />-->
                </router-link>
                <div class="text-sm w-full">
                  <h6 class="text-nowrap">{{ item.name }}</h6>
                  <ul>
                    <!--                      <li>Category: {{ item.category }}</li>-->
                    <!--                      <li>Price: \€{{ item.price }}</li>-->
                    <li>Price: €{{ item.price }}</li>
                    <div class="blood-info">
                      <div class="blood-info">
                        <p class="text-sm font-medium text-gray-700">
                          Quantity
                        </p>
                        <div class="flex items-center space-x-2">
                          <button
                            @click="
                              updateQuantity(item.package_id, item.quantity - 1)
                            "
                            :disabled="item.quantity === 1"
                            class="bg-black text-white rounded hover:bg-red-600 disabled:opacity-50"
                          >
                            <i class="fas fa-minus"></i>
                          </button>
                          <input
                            type="text"
                            class="w-12 text-center border-gray-300 border rounded"
                            :value="item.quantity"
                            readonly
                          />
                          <button
                            @click="
                              updateQuantity(item.package_id, item.quantity + 1)
                            "
                            class="bg-black text-white rounded hover:bg-green-600"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
                <div class="blood-info">
                  <p>SubTotal</p>
                  <div class="custom-increment cart">
                    <div class="input-group1">
                      <span class="input-group-btn">
                        €{{ (item.quantity * item.price).toFixed(2) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- cart summaryyyyyyyyyyyy -->
            <div v-if="cart.length">
              <!-- Cart Summary (Statistics) -->
              <div class="">
                <h4 class="text-lg font-semibold mb-4 mt-6">Cart Summary</h4>
                <div class="divide-y divide-gray-200">
                  <div class="py-2">
                    <div
                      class="flex border-b-2 border-solid pb-2 border-gray-200 justify-between"
                    >
                      <h6
                        class="flex items-center text-sm font-medium text-gray-700"
                      >
                        <i
                          class="fa-solid fa-file-invoice-dollar text-black mr-2"
                        ></i>
                        Total Items
                      </h6>
                      <span>{{ totalItems }}</span>
                    </div>
                  </div>
                  <div class="py-2">
                    <div class="flex justify-between">
                      <h6
                        class="flex items-center text-sm font-medium text-gray-700"
                      >
                        <i
                          class="fa-solid fa-dollar-sign text-pink-500 mr-2"
                        ></i>
                        Total Price
                      </h6>
                      <span>€{{ totalPriceFormatted }}</span>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <router-link
                    class="inline-block bg-black text-center w-full text-white text-sm font-medium py-2 px-4 rounded hover:bg-blue-600 transition-colors"
                    to="/product-checkout"
                  >
                    Proceed to checkout
                  </router-link>
                </div>
              </div>
            </div>
            <div v-else class="mb-5">
              <p><strong>Your cart is empty!</strong></p>
            </div>

            <related-items-2-cart
              @item-added="refreshCart"
            ></related-items-2-cart>
          </div>
        </div>
      </div>
    </div>

    <indexfooter></indexfooter>
    <bottom-navbar />
  </div>
</template>

<script setup>
import { useCart } from "@/composables/useCart";
import { ref, onMounted, computed, watch } from "vue";

const title = ref("Cart");
const text = ref("Home");
const text1 = ref("Cart");
const isLoading = ref(true);

const {
  cart,
  updateItemQuantity,
  removeItem,
  totalItems,
  // totalPrice,
  // toggleItemStatus,
  fetchCart,
} = useCart();

onMounted(async () => {
  isLoading.value = true;
  await fetchCart();
  isLoading.value = false;
});

function refreshCart() {
  isLoading.value = true;
  fetchCart()
    .then((newCart) => {
      console.log("Fetched new cart:", newCart);
      cart.value = [...newCart];
      isLoading.value = false;
    })
    .catch(() => {
      isLoading.value = false;
      console.error("Failed to fetch cart");
    });
}

watch(cart, (newCart) => {
  console.log("Cart updated:", newCart);
});
// Ensure total price calculation only happens when cart items are valid
const totalPriceFormatted = computed(() => {
  if (cart.length) {
    const total = cart.reduce(
      (acc, item) => acc + item.quantity * item.price,
      0
    );
    return total.toFixed(2); // Format to two decimal places
  }
  return "0.00"; // Return 0 if cart is empty or undefined
});

function updateQuantity(productId, quantity) {
  // Prevent quantity from going below 1
  if (quantity < 1) return;

  // Call the composable method to update the item quantity
  updateItemQuantity(productId, quantity);
}

function removeItemFromCart(productId) {
  removeItem(productId);
}
</script>
<style scoped>
/* Latest Blog Posts  */
.our-blog-section.eye-blog {
  background: transparent;
}
/* End of Latest Blog Posts  */
</style>
