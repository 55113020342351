<template>
  <div class="shadow-lg bg-white rounded-md overflow-hidden">
    <!-- Conditionally render the doctor's info or a fallback message -->
    <div v-if="doctor" class="flex flex-row justify-between gap-3 items-center">
      <!-- Doctor Image -->
      <img
        :src="doctor.image"
        alt="Doctor's Photo"
        class="w-1/2 h-auto object-cover"
      />
      <!-- Content Section -->
      <div class="flex flex-col p-2 space-y-2 flex-grow">
        <h4 class="text-lg font-bold italic">{{ doctor.name }}</h4>
        <p class="text-gray-500 my-1">{{ doctor.expertise }}</p>
        <p class="text-gray-500">{{ doctor.phone }}</p>
        <a
          :href="`tel:${doctor.phone}`"
          class="mt-auto px-4 py-2 mx-auto w-full text-center bg-black text-white rounded hover:bg-opacity-90 transition duration-150 ease-in-out"
          role="button"
        >
          Call Doctor
        </a>
      </div>
    </div>

    <!-- Fallback when no doctor information is available -->
    <div v-else class="p-4 w-full text-center">
      <p class="text-red-500">No doctor information available.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactPerson",
  props: {
    doctor: {
      type: Object,
      required: true,
      default: () => ({
        name: "",
        specialization: "",
        expertise: "",
        phone: "",
        image: "",
      }),
    },
  },
};
</script>

<style scoped>
/* Your existing styles */
</style>
