import axios from "axios";
import { router } from "@/router";
import APP_CONFIG from "./app-config";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: APP_CONFIG.apiBaseUrl,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    let errorMessage = "An unknown error occurred.";
    if (error.response) {
      switch (error.response.status) {
        case 401:
          errorMessage = "Unauthorized. Please log in again.";
          localStorage.removeItem("token");
          router.push("/login");
          break;
        case 403:
          errorMessage =
            "Forbidden. You don’t have permission to access this resource.";
          break;
        case 404:
          errorMessage = "Resource not found.";
          break;
        case 500:
          errorMessage = "Server error. Please try again later.";
          break;
        default:
          errorMessage = "An unexpected error occurred.";
      }
    } else if (error.request) {
      errorMessage = "Network error. Please check your connection.";
    }

    error.customMessage = errorMessage;

    console.error("API Error:", errorMessage);
    return Promise.reject(error);
  }
);

export default axiosInstance;
