<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main-wrapper bg-gray-100 min-h-screen">
    <div
      class="w-full bg-cover"
      style="background-image: url('/path-to-your-header-image.jpg')"
    >
      <!-- Optional overlay or additional content here -->
    </div>

    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />

    <div class="content doctor-content">
      <div class="container relative mx-auto">
        <div class="row">
          <div
            class="absolute max-h-[5rem] w-full bg-cover"
            style="background-image: url('/path-to-your-header-image.jpg')"
          ></div>
          <div class="col-lg-4 mt-[-5rem] col-xl-3 theiaStickySidebar">
            <profilesidebar></profilesidebar>
          </div>

          <div class="col-lg-8 col-xl-9">
            <div class="dashboard-header mb-4">
              <div class="max-h-[35em] w-full">
                <img
                  class="max-h-[30rem] w-full"
                  src="../../../assets/img/bg/your-plans.webp"
                  alt=""
                />
              </div>
              <ul class="header-list-btns w-full">
                <li
                  class="input-block mt-[-8rem] dash-search-input w-full mx-3"
                >
                  <h4 class="mb-3">Plans</h4>
                  <input
                    type="text"
                    class="form-control border border-gray-300 w-full"
                    placeholder="Search"
                  />
                  <span class="search-icon mt-[1.2rem]">
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </span>
                </li>
              </ul>
            </div>
            <div class="appointment-tab-head !mb-0 plans-timing-tabs">
              <div class="appointment-tabs !mb-0">
                <ul class="flex border-b m-0 h-10" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link hover:!rounded-md !rounded-none"
                      :class="{
                        'border-b-2 border-black': activeTab === 'morning',
                        'border-b-2 border-gray-300': activeTab !== 'morning',
                      }"
                      @click="setActiveTab('morning')"
                      type="button"
                    >
                      Morning<span>{{ planCounts.morning }}</span>
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link hover:!rounded-md !rounded-none"
                      :class="{
                        'border-b-2 border-black': activeTab === 'noon',
                        'border-b-2 border-gray-300': activeTab !== 'noon',
                      }"
                      @click="setActiveTab('noon')"
                      type="button"
                    >
                      Noon<span>{{ planCounts.noon }}</span>
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link hover:!rounded-md !rounded-none"
                      :class="{
                        'border-b-2 border-black': activeTab === 'evening',
                        'border-b-2 border-gray-300': activeTab !== 'evening',
                      }"
                      @click="setActiveTab('evening')"
                      type="button"
                    >
                      Evening<span>{{ planCounts.evening }}</span>
                    </button>
                  </li>
                </ul>
              </div>
              <appointments-filter></appointments-filter>
            </div>

            <div class="tab-content appointment-tab-content">
              <plan-tab
                v-for="type in ['morning', 'noon', 'evening']"
                :key="type"
                :isActive="activeTab === type"
                :plans="plansByType[type]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <indexfooter></indexfooter>
    <bottom-navbar />
  </div>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  data() {
    return {
      title: "Plans",
      text: "Home",
      text1: "Plans",
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // Reactive variable for active tab
    const activeTab = ref(route.query.tab || "morning"); // Default to 'morning' if no query

    // Get plans from Vuex store
    const plans = computed(() => store.getters["plans/plans"]);

    // Fetch plans when the component is mounted
    onMounted(() => {
      if (plans.value.length === 0) {
        store.dispatch("plans/fetchPlans");
      }
    });

    // Count plans based on time
    const planCounts = computed(() => ({
      morning: plans.value.filter(
        (plan) => plan.plan_time.toLowerCase() === "morning"
      ).length,
      noon: plans.value.filter(
        (plan) => plan.plan_time.toLowerCase() === "noon"
      ).length,
      evening: plans.value.filter(
        (plan) => plan.plan_time.toLowerCase() === "evening"
      ).length,
    }));

    // Organize plans by type
    const plansByType = computed(() => {
      return {
        morning: plans.value.filter(
          (plan) => plan.plan_time.toLowerCase() === "morning"
        ),
        noon: plans.value.filter(
          (plan) => plan.plan_time.toLowerCase() === "noon"
        ),
        evening: plans.value.filter(
          (plan) => plan.plan_time.toLowerCase() === "evening"
        ),
      };
    });

    // Set active tab and update URL
    const setActiveTab = (tab) => {
      activeTab.value = tab;
      router.push({ query: { tab } });
    };

    return {
      activeTab,
      setActiveTab,
      planCounts,
      plansByType,
    };
  },
};
</script>
