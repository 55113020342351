<template>
  <div id="app">
    <!-- Using keep-alive with dynamic include based on route metadata -->
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { loadStyles } from "@/loadStyles";

export default {
  name: "App",
  setup() {
    // Loading custom styles when the component is mounted
    onMounted(() => {
      loadStyles();
    });

    const route = useRoute();

    // Computed property to dynamically include components in keep-alive based on route meta
    const cachedViews = computed(() => {
      return route.matched.some((r) => r.meta.keepAlive) ? [route.name] : [];
    });

    return {
      cachedViews,
    };
  },
};
</script>
