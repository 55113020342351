<template>
  <div class="bg-gray-100 text-gray-900 font-sans leading-normal">
    <!-- Back Button -->
    <button @click="goBack" class="back-arrow mb-4 p-4">
      <i class="fa-solid fa-chevron-left"></i>
    </button>
    <div class="container mx-auto p-4">
      <h1 class="text-2xl font-bold text-center mb-6">Settings</h1>

      <!-- Notification Settings -->
      <div class="bg-white shadow-md rounded-lg p-4 mb-4">
        <h2 class="text-xl font-semibold mb-4">Notifications</h2>
        <button class="w-full text-left bg-gray-100 p-3 mb-2 rounded-lg">
          Email Notifications
        </button>
        <button class="w-full text-left bg-gray-100 p-3 rounded-lg">
          Push Notifications
        </button>
      </div>

      <!-- Face ID / Touch ID -->
      <div class="bg-white shadow-md rounded-lg p-4 mb-4">
        <button class="w-full text-left bg-gray-100 p-3 rounded-lg">
          Face ID / Touch ID
        </button>
      </div>

      <!-- My Profile -->
      <div class="bg-white shadow-md rounded-lg p-4 mb-4">
        <button class="w-full text-left bg-gray-100 p-3 rounded-lg">
          My Profile
        </button>
      </div>

      <!-- Credit Card Section -->
      <div class="bg-white shadow-md rounded-lg p-4 mb-4">
        <h2 class="text-xl font-semibold mb-4">Credit Cards</h2>
        <!-- Dummy Credit Card Data -->
        <div class="bg-gray-100 p-4 rounded-lg mb-2">
          <p class="font-semibold">Card 1</p>
          <p>Card Number: 1234 5678 9101 1121</p>
          <p>Expiry Date: 12/25</p>
        </div>
        <div class="bg-gray-100 p-4 rounded-lg">
          <p class="font-semibold">Card 2</p>
          <p>Card Number: 4321 8765 1101 1221</p>
          <p>Expiry Date: 11/24</p>
        </div>
      </div>

      <!-- Legal Section -->
      <div class="bg-white shadow-md rounded-lg p-4 mb-4">
        <h2 class="text-xl font-semibold mb-4">Legal</h2>
        <button
          @click="navigateTo('PrivacyNotice')"
          class="w-full text-left bg-gray-100 p-3 mb-2 rounded-lg"
        >
          Privacy Notice
        </button>
        <button
          @click="navigateTo('TermsOfUse')"
          class="w-full text-left bg-gray-100 p-3 mb-2 rounded-lg"
        >
          Terms of Use
        </button>
        <button
          @click="navigateTo('Imprint')"
          class="w-full text-left bg-gray-100 p-3 rounded-lg"
        >
          Imprint
        </button>
      </div>

      <!-- Help Section -->
      <div class="bg-white shadow-md rounded-lg p-4 mb-4">
        <button
          @click="navigateTo('Help')"
          class="w-full text-left bg-gray-100 p-3 rounded-lg"
        >
          Help (Support & FAQ)
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Settings",
  methods: {
    goBack() {
      this.$router.go(-1); // Navigates back to the previous route
    },
    navigateTo(page) {
      // This method will navigate to the appropriate pages like Privacy Notice, Terms of Use, Imprint, and Help
      this.$router.push({ name: page });
    },
  },
};
</script>

<style scoped>
/* Customize the back button as needed */
.back-arrow {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
}
</style>
