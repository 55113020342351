<template>
  <div class="bg-gray-100 text-gray-900 font-sans leading-normal">
    <!-- Back Button -->
    <button @click="goBack" class="back-arrow">
      <i class="fa-solid fa-chevron-left"></i>
    </button>
    <div class="container mx-auto p-8">
      <h1 class="text-3xl font-bold text-center mb-6">Privacy Notice</h1>
      <div class="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
        <h2 class="text-2xl font-bold mb-4">Introduction</h2>
        <p>
          We are committed to protecting your personal data. This Privacy Notice
          explains how we collect, use, and disclose information that you
          provide to us through the use of our Health App.
        </p>

        <h3 class="text-xl font-bold mt-6 mb-2">1. Data We Collect</h3>
        <p>
          We may collect personal information such as your name, email address,
          phone number, and health-related data that you provide when using the
          app.
        </p>

        <h3 class="text-xl font-bold mt-6 mb-2">2. How We Use Your Data</h3>
        <p>
          We use your data to improve your experience with our app, provide
          health-related services, and for internal business purposes such as
          analytics and research.
        </p>

        <h3 class="text-xl font-bold mt-6 mb-2">3. Data Sharing</h3>
        <p>
          We may share your data with third-party service providers, such as
          cloud storage providers, to help us operate and improve the app.
        </p>

        <h3 class="text-xl font-bold mt-6 mb-2">4. Data Security</h3>
        <p>
          We take reasonable steps to protect your data from unauthorized access
          or disclosure. However, no method of transmission over the Internet or
          method of electronic storage is 100% secure.
        </p>

        <h3 class="text-xl font-bold mt-6 mb-2">5. Your Rights</h3>
        <p>
          You have the right to access, correct, or delete the personal data we
          have collected about you. Please contact us if you would like to
          exercise these rights.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyNotice",
  methods: {
    goBack() {
      this.$router.go(-1); // Navigates back to the previous route
    },
  },
};
</script>
