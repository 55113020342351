/* eslint-disable vue/multi-word-component-names */
import { createApp } from "vue";
import { router } from "./router";
import App from "./App.vue";
import store from "./store";

import VueSelect from "vue3-select2-component";
import VCalendar from "v-calendar";
import VueFeather from "vue-feather";
import DatePicker from "vue3-datepicker";
import VueApexCharts from "vue3-apexcharts";
import Vue3Autocounter from "vue3-autocounter";
import VueTelInput from "vue3-tel-input";
import VueEasyLightbox from "vue-easy-lightbox";

// plugins
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "vue3-tel-input/dist/vue3-tel-input.css";

import VueSweetalert2 from "./plugins/vue-sweetalert2";

// Import the global stylesheet
import "./assets/global.css";

/***********************************************************************/
/* Frontend */
/***********************************************************************/

//Breadcrumb
import BlogSidebar from "@/views/layouts/blogsidebar.vue";
import Mainmenu from "@/components/mainmenu.vue";
import Mainnav from "@/components/mainnav.vue";
import BottomNavbar from "@/components/mobile/BottomNav.vue";
import MobilMenuModal from "@/components/mobile/MenuModal.vue";

import Breadcrumb from "@/components/breadcrumb/aboutus.vue";
import Breadcrumb1 from "@/components/breadcrumb/breadcrumb1.vue";

import Profilewidget from "@/components/profilewidget.vue";
import DoctorFooter from "@/views/layouts/doctor-footer.vue";
import DoctorSidebar from "@/views/layouts/doctorsidebar.vue";

//pages

import Header from "@/views/layouts/header.vue";
import HeaderTwo from "@/views/layouts/headertwo.vue";
import Headerinfo from "@/views/layouts/header-info.vue";
import Cursor from "@/views/layouts/cursor.vue";
import Scrolltotop from "@/views/layouts/scrolltotop.vue";
import ProfileSidebar from "@/views/layouts/profilesidebar.vue";

import Footer from "@/views/layouts/footer.vue";
import IndexBanner from "@/views/home/indexbanner.vue";
import IndexDoctor from "@/views/home/indexdoctor.vue";
import IndexWork from "@/views/home/indexwork.vue";
import IndexApp from "@/views/home/indexapp.vue";
import IndexTestimonial from "@/views/home/indextestimonial.vue";
import IndexPartners from "@/views/home/indexpartners.vue";
import IndexFooter from "@/views/home/footer.vue";

// patients
import PatientPlansMorning from "@/views/patients/plans/patient-plans-morning.vue";
import PatientPlansLunch from "@/views/patients/plans/patient-plans-lunch.vue";
import PatientPlansEvening from "@/views/patients/plans/patient-plans-evening.vue";
import PlanTab from "@/views/patients/plans/plan-tab.vue";

// blog
import LatestBlogPosts from "@/components/blogs/LatestBlogPosts.vue";

// items
import RelatedItems2Cart from "@/components/items/RelatedItems2Cart.vue";

//Auth
import PasswordConfirmationModal from "@/components/authentication/PasswordConfirmationModal.vue";

// general components
import SafetyWarning from "@/components/SafetyWarning.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

/***********************************************************************/
/*  Pharmacy */
/***********************************************************************/

const app = createApp(App);

// Check if token exists and validate it
const token = localStorage.getItem("token");
if (token) {
  // Optionally, validate token with API here
  store.dispatch("setAuthToken", token);
  store.dispatch("setIsAuthenticated", true);
}

app.use(router);
app.use(store);

// Breadcrumb
app.component("breadcrumb", Breadcrumb);

app.component("breadcrumb1", Breadcrumb1);
app.component("blogsidebar", BlogSidebar);
app.component("mainmenu", Mainmenu);
app.component("mainnav", Mainnav);
app.component("bottom-navbar", BottomNavbar);
app.component("mobile-menu-modal", MobilMenuModal);
app.component("profilewidget", Profilewidget);
app.component("doctor-footer", DoctorFooter);
app.component("doctorsidebar", DoctorSidebar);

//pages
app.component("layoutheader", Header);
app.component("layoutheadertwo", HeaderTwo);
app.component("headerinfo", Headerinfo);
app.component("cursor", Cursor);
app.component("scrolltotop", Scrolltotop);
app.component("footerindex", Footer);
app.component("indexbanner", IndexBanner);
app.component("indexdoctor", IndexDoctor);
app.component("indexwork", IndexWork);
app.component("indexapp", IndexApp);
app.component("indextestimonial", IndexTestimonial);
app.component("indexpartners", IndexPartners);
app.component("indexfooter", IndexFooter);

//Patient pages
app.component("profilesidebar", ProfileSidebar);
app.component("patient-plans-morning", PatientPlansMorning);
app.component("patient-plans-lunch", PatientPlansLunch);
app.component("patient-plans-evening", PatientPlansEvening);
app.component("plan-tab", PlanTab);

// Blog
app.component("latest-blog-posts", LatestBlogPosts);

// Items
app.component("related-items-2-cart", RelatedItems2Cart);

// Authentication
app.component("password-confirmation-modal", PasswordConfirmationModal);

// General
app.component("safety-warning", SafetyWarning);
app.component("loading-spinner", LoadingSpinner);

// Modal
app.component("vue3-autocounter", Vue3Autocounter);
app.component("vue-select", VueSelect);
app.component(VueFeather.name, VueFeather);
app.component("datepicker", DatePicker);
app.use(VueApexCharts);
app.use(VueEasyLightbox).use(VueTelInput);
app.use(VCalendar, {});

app.use(VueSweetalert2);

app.mount("#app");
