import axiosInstance from "@/constants/axios";
import { handleRequestError } from "@/utils/handle-req-err";
import END_POINTS from "@/constants/endponsts";

// Post categories list
export const postDiagnosis = async () => {
  try {
    const res = await axiosInstance.get(
      `${END_POINTS.get_post_categories_list}`
    );
    console.log(res, "dddddddd");
    return res.data;
  } catch (err) {
    return handleRequestError(err);
  }
};

// Post category details
export const postCategoryDetails = async (id, data) => {
  try {
    const res = await axiosInstance.get(
      `${END_POINTS.get_category_detail}${id}`,
      data
    );
    return res.data;
  } catch (err) {
    return handleRequestError(err);
  }
};

// Post details
export const postParameterDetails = async (id, data) => {
  try {
    const res = await axiosInstance.get(
      `${END_POINTS.get_post_detail}${id}`,
      data
    );
    return res.data;
  } catch (err) {
    return handleRequestError(err);
  }
};
