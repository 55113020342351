<template>
  <div class="content login-page pt-0">
    <div class="container-fluid">
      <!-- Code Entry Content -->
      <div class="account-content">
        <div class="row align-items-center">
          <div class="login-right">
            <div class="inner-right-login">
              <div class="login-header">
                <div class="logo-icon">
                  <img src="assets/img//logo.svg" alt="doccure-logo" />
                </div>
                <div class="step-list">
                  <ul>
                    <li>
                      <a href="javascript:void(0)" class="active-done">1</a>
                    </li>
                    <li><a href="javascript:void(0)" class="active">2</a></li>
                    <li><a href="javascript:void(0)">3</a></li>
                  </ul>
                </div>
                <form @submit.prevent="handleSubmit" id="code_entry_form">
                  <!-- Display the message if available -->
                  <div v-if="message" class="alert alert-info mt-3">
                    {{ message }}
                  </div>
                  <div class="mt-5">
                    <b-button
                      class="btn btn-primary w-100 btn-lg login-btn"
                      type="submit"
                    >
                      Verify Code
                    </b-button>
                  </div>
                </form>
              </div>
            </div>
            <div class="login-bottom-copyright">
              <span
                >© {{ new Date().getFullYear() }} Health app. All rights
                reserved.</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- /Code Entry Content -->
    </div>
    <!-- Loading Spinner -->
    <loading-spinner v-if="isLoading" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { verifyRegistrationCode } from "@/services/auth";

const store = useStore();
const router = useRouter();
const registrationCode = ref("");
const codeError = ref("");
const message = ref("");
const isLoading = ref(false);

const email = computed(() => store.getters.userEmail);

onMounted(() => {
  // Access the message from Vuex store
  message.value = store.getters.registrationMessage;
});

const handleSubmit = async () => {
  isLoading.value = true;

  codeError.value = "";

  try {
    const response = await verifyRegistrationCode({
      confirmation_code: registrationCode.value,
      email: email.value,
    });

    if (response.success) {
      router.push("/register-step-two");
    } else {
      codeError.value = "Invalid code. Please try again.";
    }
  } catch (error) {
    codeError.value =
      "An error occurred while verifying the code. Please try again.";
  } finally {
    isLoading.value = false;
  }
};
</script>
