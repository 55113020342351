import axiosInstance from "@/constants/axios";
import { handleRequestError } from "@/utils/handle-req-err";
import END_POINTS from "@/constants/endponsts";

// Post categories list
export const homeData = async () => {
  try {
    const res = await axiosInstance.get(`${END_POINTS.get_home_data}`);
    console.log(res, "dddddddd");
    return res.data;
  } catch (err) {
    return handleRequestError(err);
  }
};
