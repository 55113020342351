<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />

    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="invoice-content">
                <div class="invoice-item">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="invoice-logo">
                        <img src="assets/img//logo.svg" alt="logo" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <p class="invoice-details">
                        <strong>Order:</strong> #{{ orderDetails?.order_id }}
                        <br />
                        <strong>Issued:</strong>
                        {{
                          new Date(
                            orderDetails?.created_at
                          ).toLocaleDateString()
                        }}
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Invoice Item -->
                <div class="invoice-item">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="invoice-info">
                        <strong class="customer-text">Invoice From</strong>
                        <p class="invoice-details invoice-details-two">
                          newboxes GmbH<br />
                          Fallenbrunnen 14<br />
                          88045 Friedrichshafen<br />
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="invoice-info invoice-info2">
                        <strong class="customer-text">Invoice To</strong>
                        <p class="invoice-details">
                          Walter Roberson <br />
                          299 Star Trek Drive, Panama City, <br />
                          Florida, 32405, USA <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Invoice Item -->

                <!-- Invoice Item -->
                <div class="invoice-item">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="invoice-info">
                        <strong class="customer-text">Payment Method</strong>
                        <p class="invoice-details invoice-details-two">
                          Debit Card <br />
                          XXXXXXXXXXXX-2541 <br />
                          HDFC Bank<br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Invoice Item -->

                <!-- Invoice Item -->
                <div class="invoice-item invoice-table-wrap">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="invoice-table table table-bordered">
                          <thead>
                            <tr>
                              <th>Description</th>
                              <th class="text-center">Quantity</th>
                              <th class="text-center">VAT</th>
                              <th class="text-end">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in orderDetails?.packages"
                              :key="item.id"
                            >
                              <td>{{ item.item_name }}</td>
                              <td class="text-center">{{ item.count }}</td>
                              <td class="text-center">{{ item.vat ?? 0 }}</td>
                              <td class="text-end">€{{ item.total_price }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-6 col-xl-4 ms-auto">
                      <div class="table-responsive">
                        <table class="invoice-table-two table">
                          <tbody>
                            <tr>
                              <th>Subtotal:</th>
                              <!--                            <td><span>€{{ orderDetails?.pakcages_sum_price }}</span></td>-->
                              <td>
                                <span>€{{ totalPrice.toFixed(2) }}</span>
                              </td>
                            </tr>
                            <tr>
                              <th>Discount:</th>
                              <td><span>0%</span></td>
                            </tr>
                            <tr>
                              <th>Total Amount:</th>
                              <!--                            <td><span>€{{ orderDetails?.pakcages_sum_price }}</span></td>-->
                              <td>
                                <span>€{{ totalPrice.toFixed(2) }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Invoice Item -->

                <!-- Invoice Information -->
                <div class="other-info">
                  <h4>Other information</h4>
                  <p class="text-muted mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus sed dictum ligula, cursus blandit risus. Maecenas
                    eget metus non tellus dignissim aliquam ut a ex. Maecenas
                    sed vehicula dui, ac suscipit lacus. Sed finibus leo vitae
                    lorem interdum, eu scelerisque tellus fermentum. Curabitur
                    sit amet lacinia lorem. Nullam finibus pellentesque libero.
                  </p>
                </div>
                <!-- /Invoice Information -->

                <div class="submit-section mt-4">
                  <b-button
                    type="submit"
                    class="btn btn-primary submit-btn"
                    @click="finalizePayment"
                  >
                    Finalize Your Payment
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
    <indexfooter></indexfooter>
    <bottom-navbar />
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { checkoutPayment } from "@/services/order";
import { useCart } from "@/composables/useCart";

const router = useRouter();

const title = ref("Payment View");
const text = ref("Home");
const text1 = ref("Payment View");

const orderDetails = ref(null);
const { totalPrice } = useCart();

onMounted(async () => {
  const order = JSON.parse(localStorage.getItem("NewOrder"));
  console.log("orderDetails:", order);
  if (order) {
    try {
      orderDetails.value = order;
    } catch (error) {
      console.error("Failed to fetch order details:", error);
      alert("Failed to fetch order details.");
      router.push("/product-checkout");
    }
  } else {
    alert("No order ID found.");
    router.push("/product-checkout");
  }
});

async function finalizePayment() {
  if (orderDetails.value) {
    try {
      const response = await checkoutPayment(orderDetails.value.order_id);
      if (response.data.data.status === "done") {
        router.push("/payment-success");
      } else {
        alert("Payment failed.");
      }
    } catch (error) {
      console.error("Payment finalization failed:", error);
      alert("Payment finalization failed.");
    }
  }
}
</script>
<style scoped>
.invoice-item .invoice-logo img {
  width: auto;
  max-height: 18px;
}
</style>
