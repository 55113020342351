import {
  getDiagnosis,
  getCategoryDetails,
  getParameterDetails,
} from "@/services/tests";

export default {
  namespaced: true,
  state() {
    return {
      tests: [], // Store all diagnosis data (tests)
      currentTest: null, // Store current test details
      categories: {}, // Store categories by ID
      parameters: {}, // Store parameters by ID
      isLoading: false, // Loading state for fetching tests
      errorMessage: null, // Store error messages
    };
  },
  mutations: {
    SET_TESTS(state, tests) {
      state.tests = tests; // Set the fetched diagnosis tests
    },
    SET_CURRENT_TEST(state, test) {
      state.currentTest = test; // Set current selected test details
    },
    SET_CATEGORY_DETAILS(state, { id, data }) {
      state.categories = { ...state.categories, [id]: data }; // Cache category details by ID
    },
    SET_PARAMETER_DETAILS(state, { id, data }) {
      state.parameters = { ...state.parameters, [id]: data }; // Cache parameter details by ID
    },
    SET_LOADING(state, status) {
      state.isLoading = status; // Update the loading state
    },
    SET_ERROR(state, errorMessage) {
      state.errorMessage = errorMessage; // Update error message
    },
    CLEAR_ERROR(state) {
      state.errorMessage = null; // Clear any existing errors
    },
  },
  actions: {
    async fetchTests({ commit, state }) {
      if (state.tests.length > 0) return; // If tests are already cached, no need to fetch again

      commit("SET_LOADING", true);
      commit("CLEAR_ERROR");

      try {
        const response = await getDiagnosis(); // Fetch diagnosis tests
        if (response.data) {
          commit("SET_TESTS", response.data); // Ensure response has a data property
        } else {
          throw new Error("Unexpected API response structure");
        }
      } catch (error) {
        commit("SET_ERROR", "Error fetching diagnosis tests.");
        console.error("Error fetching diagnosis tests:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async fetchCategoryDetails({ commit, state }, id) {
      // Only fetch if the category is not already in the state
      if (state.categories[id]) return state.categories[id];

      commit("SET_LOADING", true);
      commit("CLEAR_ERROR");

      try {
        const response = await getCategoryDetails(id); // Fetch category details

        if (response.data) {
          commit("SET_CATEGORY_DETAILS", { id, data: response.data });
        } else {
          throw new Error(
            "Unexpected API response structure for category details"
          );
        }
      } catch (error) {
        commit("SET_ERROR", `Error fetching category details for ID: ${id}`);
        console.error(`Error fetching category details for ID: ${id}`, error);
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async fetchParameterDetails({ commit, state }, id) {
      // Only fetch if the parameter is not already in the state
      if (state.parameters[id]) return state.parameters[id];

      commit("SET_LOADING", true);
      commit("CLEAR_ERROR");

      try {
        const response = await getParameterDetails(id); // Fetch parameter details
        if (response.data) {
          commit("SET_PARAMETER_DETAILS", { id, data: response.data });
        } else {
          throw new Error(
            "Unexpected API response structure for parameter details"
          );
        }
      } catch (error) {
        commit("SET_ERROR", `Error fetching parameter details for ID: ${id}`);
        console.error(`Error fetching parameter details for ID: ${id}`, error);
      } finally {
        commit("SET_LOADING", false);
      }
    },
  },
  getters: {
    tests: (state) => state.tests, // Getter to access all tests
    currentTest: (state) => state.currentTest, // Getter to access current test details
    getCategoryById: (state) => (id) => state.categories[id], // Getter for category details by ID
    getParameterById: (state) => (id) => state.parameters[id], // Getter for parameter details by ID
    hasPreviousTest: (state) => (currentTestId) => {
      const index = state.tests.findIndex(
        (test) => test.item_id === currentTestId
      );
      return index > 0;
    },
    hasNextTest: (state) => (currentTestId) => {
      const index = state.tests.findIndex(
        (test) => test.item_id === currentTestId
      );
      return index >= 0 && index < state.tests.length - 1;
    },
    getAdjacentTestId: (state) => (currentTestId, direction) => {
      const index = state.tests.findIndex(
        (test) => test.item_id === currentTestId
      );
      if (direction === "previous" && index > 0) {
        return state.tests[index - 1].item_id;
      }
      if (direction === "next" && index < state.tests.length - 1) {
        return state.tests[index + 1].item_id;
      }
      return null;
    },
  },
};
