import axiosInstance from "@/constants/axios";
import END_POINTS from "@/constants/endponsts";
import { handleRequestError } from "@/utils/handle-req-err"; // Import the error handler

// List of Plans
export const getPlans = async () => {
  try {
    const response = await axiosInstance.get(END_POINTS.get_plans);
    console.log("response.data: ", response.data);
    return response.data.data[0].items; // Assuming API response has a 'data' array
  } catch (error) {
    return handleRequestError(error); // Use handleRequestError for structured error handling
  }
};

// Fetch a single plan item by ID
export const getPlanById = async (id) => {
  try {
    const response = await axiosInstance.get(
      `${END_POINTS.get_plan_by_id}${id}`
    );
    console.log("Fetched plan item:", response.data);
    return response.data.data; // Adjust based on the API response structure
  } catch (error) {
    return handleRequestError(error); // Use handleRequestError for structured error handling
  }
};
