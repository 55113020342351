import { reactive, computed } from "vue";
import {
  createOrder,
  updateOrder,
  getOrders,
  deleteOrder,
} from "@/services/order";

const cart = reactive([]);
let orderId = null;

export function useCart() {
  // Fetch the current cart from the backend
  async function fetchCart() {
    try {
      const response = await getOrders(1, 2); // Get paginated orders (assuming the first page returns active orders)
      const orders = response.data.data;
      // Clear the local cart
      cart.splice(0, cart.length);

      // Find the order with status 'new'
      const newOrder = orders.find((order) => order.status === "new");
      if (newOrder) {
        orderId = newOrder.order_id;

        // Populate the cart with the order's packages (items)
        newOrder.packages.forEach((packageItem) => {
          cart.push({
            package_id: packageItem.package_id,
            name: packageItem.item_name,
            image: packageItem.image,
            price: packageItem.package_price,
            // price: parseFloat(packageItem.package_price) || 0,
            quantity: packageItem.count,
            totalPrice: packageItem.total_price,
          });
        });
      } else {
        orderId = null; // No active order found
      }
    } catch (error) {
      console.error("Error fetching cart:", error.message);
    }
  }

  // Add an item to the cart
  async function addItem(product) {
    // Before adding the item, fetch the latest cart data from the backend
    await fetchCart();

    // Check if the item already exists in the cart
    const existingProduct = cart.find(
      (item) => item.package_id === product.packageID
    );

    if (existingProduct) {
      console.warn("Item already exists in the cart.");
      return;
    }

    // Add the new item to the local cart
    const newItem = {
      package_id: product.packageID,
      name: product.name,
      image: product.image,
      price: product.price,
      // price: parseFloat(product.price) || 0,
      quantity: 1,
    };
    cart.push(newItem);
    console.log("Cart after adding item:", cart);

    // Sync the cart with the backend
    await syncCartWithBackend();
  }

  // Update the quantity of an item in the cart
  async function updateItemQuantity(packageID, quantity) {
    const item = cart.find((item) => item.package_id === packageID);

    if (!item) {
      console.warn("Item not found in the cart.");
      return;
    }

    // Update the quantity locally
    item.quantity = quantity;
    console.log("item.quantity", item);
    // Sync the cart with the backend
    await syncCartWithBackend();
  }

  // Synchronize the cart with the backend (create or update the order)
  async function syncCartWithBackend() {
    try {
      if (orderId === null) {
        // No active order exists, create a new one
        const response = await createOrder(
          cart.map((item) => ({
            package_id: item.package_id,
            count: item.quantity,
          }))
        );
        orderId = response.data.orderId;

        console.log("New order created with ID:", orderId);
      } else {
        console.log("Updating order with ID:", orderId);
        console.log("Updating order with IDcartcart:", cart.length);
        if (cart.length) {
          // Active order exists, update it with the current cart items
          await updateOrder(
            orderId,
            cart.map((item) => ({
              package_id: item.package_id,
              count: item.quantity,
            }))
          );
        } else {
          await deleteOrder(orderId);
        }
        console.log("Order updated successfully");
      }
    } catch (error) {
      console.error("Error syncing cart with backend:", error.message);
    }
  }

  // Remove an item from the cart
  async function removeItem(packageID) {
    console.log("packageID;", packageID);
    console.log("cart;", cart);
    const itemIndex = cart.findIndex((item) => item.package_id === packageID);

    if (itemIndex === -1) {
      console.warn("Item not found in the cart.");
      return;
    }

    // Remove the item from the local cart
    cart.splice(itemIndex, 1);
    console.log("cart remove;;", cart);
    // Sync the cart with the backend (update the order with the remaining items)
    await syncCartWithBackend();
  }

  // Calculate the total items in the cart
  const totalItems = computed(() =>
    cart.reduce((acc, item) => acc + item.quantity, 0)
  );

  // Calculate the total price of the items in the cart
  const totalPrice = computed(() =>
    cart.reduce((acc, item) => acc + item.quantity * item.price, 0)
  );

  return {
    cart,
    addItem,
    updateItemQuantity,
    removeItem,
    totalItems,
    totalPrice,
    fetchCart,
  };
}
