<template>
  <div>
    <h3>Dot Chart Visualization</h3>

    <!-- Filter Buttons -->
    <div class="filter-buttons mb-4 w-full mt-10">
      <button @click="filterData('1year')" class="btn">1 Year</button>
      <button @click="filterData('9months')" class="btn">9 Months</button>
      <button @click="filterData('6months')" class="btn">6 Months</button>
    </div>

    <apexchart
      v-if="filteredValues && filteredValues.length > 0"
      type="scatter"
      :options="chartOptions"
      :series="chartSeries"
    ></apexchart>

    <div v-else>No data available for chart.</div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "scatter",
          zoom: {
            enabled: true,
            type: "xy",
          },
        },
        xaxis: {
          type: "datetime", // Use datetime for x-axis
          title: {
            text: "Date",
          },
        },
        yaxis: {
          title: {
            text: "Value",
          },
          tickAmount: 10,
        },
        markers: {
          size: 5,
        },
      },
      chartSeries: [
        {
          name: "Leukozyten Values",
          data: [],
        },
      ],
      filteredValues: [], // To store filtered values
    };
  },
  watch: {
    values(newValues) {
      console.log(newValues);
      // Update filtered values when prop changes
      this.filteredValues = this.filterByDateRange(this.values, "1year");
      this.updateChartSeries();
    },
  },
  mounted() {
    // Default to 1 year filter on mount
    this.filteredValues = this.filterByDateRange(this.values, "1year");
    this.updateChartSeries();
  },
  methods: {
    filterData(range) {
      // Filter values based on selected range
      this.filteredValues = this.filterByDateRange(this.values, range);
      this.updateChartSeries();
    },
    filterByDateRange(values, range) {
      const now = new Date();
      let rangeDate;

      // Calculate the start date for the filter
      if (range === "1year") {
        rangeDate = new Date(now.setFullYear(now.getFullYear() - 1));
      } else if (range === "9months") {
        rangeDate = new Date(now.setMonth(now.getMonth() - 9));
      } else if (range === "6months") {
        rangeDate = new Date(now.setMonth(now.getMonth() - 6));
      }

      // Filter values based on the calculated range date
      return values.filter((item) => new Date(item.created_at) >= rangeDate);
    },
    formatValues() {
      // Format data for the chart as [timestamp, value] pairs
      return this.filteredValues.map((item) => {
        return [new Date(item.created_at).getTime(), item.value];
      });
    },
    updateChartSeries() {
      // Update chart series with filtered values
      this.chartSeries[0].data = this.formatValues();
    },
  },
};
</script>

<style scoped>
/* Optional: style your chart here */
.filter-buttons {
  display: flex;
  gap: 10px;
  width: 100%;
}

.btn {
  display: inline-block;
  background-color: #3490dc;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.btn:hover {
  background-color: #2779bd;
}
</style>
