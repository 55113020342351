<template>
  <div class="post-detail p-6">
    <img
      v-if="!isLoading"
      :src="post.image"
      alt="Main post"
      class="rounded-lg mb-4 w-full object-cover h-64"
    />
    <h1 class="text-3xl font-bold mb-4">{{ post.name }}</h1>
    <p v-html="post.description" class="text-gray-700 text-lg mb-6"></p>
    <!-- Loading spinner -->
    <div v-if="isLoading" class="flex justify-center items-center h-screen">
      <i class="fa fa-spinner fa-spin text-4xl"></i>
    </div>
    <!-- Carousel for Items -->
    <div v-else-if="post.items && post.items.length > 0" class="mt-8">
      <carousel
        :autoplay="2000"
        :navigationEnabled="true"
        :paginationEnabled="true"
        :itemsToShow="3"
      >
        <slide
          v-for="item in post.items"
          class="flex flex-col items-start gap-2"
          :key="item.id"
        >
          <div class="flex items-center justify-center">
            <img
              :src="item.image"
              :alt="item.name"
              class="rounded-lg object-cover block mx-auto w-24 h-24"
            />
          </div>
          <div class="text-center mt-2 font-medium h-20 mx-3">
            {{ item.name }}
          </div>
        </slide>
      </carousel>
    </div>
    <!-- No items message -->
    <div v-else class="text-center text-gray-600">No items available.</div>
  </div>
  <bottom-navbar />
</template>

<script>
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { postParameterDetails } from "@/services/posts";

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      post: {},
      isLoading: true,
    };
  },
  created() {
    this.fetchPost();
  },
  methods: {
    async fetchPost() {
      const postId = this.$route.params.postId;
      try {
        const response = await postParameterDetails(postId);
        if (response.data) {
          this.post = response.data;
        }
      } catch (error) {
        console.error("Failed to fetch post:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.post-detail {
  max-width: 768px;
  margin: auto;
  border-radius: 10px;
}

h1 {
  color: #2a4365; /* Darker blue for better focus on the title */
}

p {
  line-height: 1.6; /* Improve readability */
}
</style>
