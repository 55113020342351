import axiosInstance from "@/constants/axios";
import { handleRequestError } from "@/utils/handle-req-err";
import END_POINTS from "@/constants/endponsts";

export const getDiagnosis = async () => {
  try {
    const res = await axiosInstance.get(`${END_POINTS.get_diagnosis}`);
    return res.data;
  } catch (err) {
    return handleRequestError(err);
  }
};
export const getCategoryDetails = async (id) => {
  try {
    const res = await axiosInstance.get(
      `${END_POINTS.get_category_details}${id}`
    );
    return res.data;
  } catch (err) {
    return handleRequestError(err);
  }
};

export const getParameterDetails = async (id) => {
  try {
    const res = await axiosInstance.get(
      `${END_POINTS.get_parameter_details}${id}`
    );
    return res.data;
  } catch (err) {
    return handleRequestError(err);
  }
};
