<template>
  <!-- Page Content -->
  <div class="content login-page pt-0">
    <div class="container-fluid">
      <!-- Code Entry Content -->
      <div class="account-content">
        <div class="row align-items-center">
          <div class="login-right">
            <div class="inner-right-login">
              <div class="login-header">
                <div class="logo-icon">
                  <img src="assets/img//logo.svg" alt="doccure-logo" />
                </div>
                <div class="step-list">
                  <ul>
                    <li>
                      <a href="javascript:void(0)" class="active-done">1</a>
                    </li>
                    <li><a href="javascript:void(0)" class="active">2</a></li>
                    <li><a href="javascript:void(0)">3</a></li>
                    <li><a href="javascript:void(0)">4</a></li>
                  </ul>
                </div>
                <form @submit.prevent="handleSubmit" id="code_entry_form">
                  <div class="form-group">
                    <h3>Enter Registration Code</h3>
                    <label for="registration_code">Code</label>
                    <input
                      type="text"
                      id="registration_code"
                      v-model="registrationCode"
                      class="form-control"
                      placeholder="Enter the code you received"
                      required
                    />
                    <div v-if="codeError" class="text-danger">
                      {{ codeError }}
                    </div>
                  </div>
                  <div class="mt-5">
                    <b-button
                      class="btn btn-primary w-100 btn-lg login-btn"
                      type="submit"
                    >
                      Verify Code
                    </b-button>
                  </div>
                </form>
              </div>
            </div>
            <div class="login-bottom-copyright">
              <span
                >© {{ new Date().getFullYear() }} Doccure. All rights
                reserved.</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- /Code Entry Content -->
    </div>
  </div>
  <!-- /Page Content -->
</template>
<script>
export default {
  data() {
    return {
      registrationCode: "",
      codeError: "",
    };
  },
  methods: {
    async handleSubmit() {
      // Reset error message
      this.codeError = "";

      // Simulate code verification (replace with actual API call)
      const validCode = "123456"; // Example of a valid code
      if (this.registrationCode === validCode) {
        // Redirect to step1 after successful code verification
        this.$router.push("/doctor-register-step1");
      } else {
        // Display error message for invalid code
        this.codeError = "Invalid code. Please try again.";
      }
    },
  },
};
</script>
<!--
<style scoped>
/* Styles for Code Entry Page */
.login-header {
  text-align: center;
  margin-bottom: 20px;
}

.step-list ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.step-list li {
  margin: 0 5px;
}

.step-list a {
  text-decoration: none;
  color: #000;
  padding: 10px 15px;
  border-radius: 5px;
}

.step-list .active {
  background-color: #007bff;
  color: #fff;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 5px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.text-danger {
  color: #dc3545;
}

.login-bottom-copyright {
  text-align: center;
  margin-top: 20px;
}
</style>-->
