<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <!-- Page Content -->
    <div class="content top-space">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <!-- Register Content -->
            <div class="account-content">
              <div class="row align-items-center justify-content-center">
                <div class="col-md-12 col-lg-6 login-right">
                  <div class="login-header">
                    <h3>Access to Your Health</h3>
                  </div>
                  <div class="register-header-img">
                    <img
                      src="assets/img//supplements/supplement-3.jpg"
                      class="img-fluid"
                      alt="Health app forget password"
                    />
                  </div>
                  <!-- Register Form -->
                  <form @submit.prevent="submitForm">
                    <div v-if="errorMessage" class="alert alert-danger mt-3">
                      {{ errorMessage }}
                    </div>

                    <div
                      v-if="Object.keys(errorMessages).length"
                      class="alert alert-danger mt-3"
                    >
                      <div
                        v-for="(messages, field) in errorMessages"
                        :key="field"
                      >
                        <!-- Check if messages is an array -->
                        <p
                          v-if="Array.isArray(messages)"
                          v-for="message in messages"
                          :key="message"
                        >
                          {{ message }}
                        </p>
                        <!-- Handle messages as a single string -->
                        <p v-else>{{ messages }}</p>
                      </div>
                    </div>

                    <div class="mb-3 form-focus">
                      <input
                        type="text"
                        class="form-control floating"
                        v-model="providedId"
                        required
                      />
                      <label class="focus-label">Provided ID</label>
                      <div v-if="idError" class="text-danger">
                        {{ idError }}
                      </div>
                    </div>
                    <div class="mb-3 form-focus">
                      <input
                        type="email"
                        class="form-control floating"
                        v-model="email"
                        required
                      />
                      <label class="focus-label">Email</label>
                      <div v-if="emailError" class="text-danger">
                        {{ emailError }}
                      </div>
                    </div>
                    <div class="text-end">
                      <router-link class="forgot-link" to="/login">
                        Already have an account?
                      </router-link>
                    </div>
                    <b-button
                      class="btn btn-primary w-100 btn-lg login-btn"
                      type="submit"
                    >
                      Send Verification Code
                    </b-button>
                  </form>
                  <!-- /Register Form -->
                </div>
              </div>
            </div>
            <!-- /Register Content -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <indexfooter />
    <!-- Loading Spinner -->
    <loading-spinner v-if="isLoading" />

    <!--    <bottom-navbar />-->
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { registerUser } from "@/services/auth";

const store = useStore();
const router = useRouter();

const email = ref("");
const providedId = ref("");
const emailError = ref("");
const idError = ref("");
const isLoading = ref(false);
const errorMessage = computed(() => store.getters.errorMessage);
const errorMessages = ref({});

/*
onMounted(() => {
  store.dispatch('clearErrorMessage');
});
*/

const validateForm = () => {
  let isValid = true;

  errorMessages.value = {};

  // Validate Provided ID
  if (!providedId.value) {
    errorMessages.value.identifier = ["Provided ID is required."];
    isValid = false;
  }

  // Validate Email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email.value) {
    errorMessages.value.email = ["Email is required."];
    isValid = false;
  } else if (!emailRegex.test(email.value)) {
    errorMessages.value.email = ["Enter a valid email address."];
    isValid = false;
  }

  return isValid;
};

const submitForm = async () => {
  if (validateForm()) {
    isLoading.value = true;

    try {
      const userData = {
        email: email.value,
        identifier: providedId.value,
      };
      const result = await registerUser(userData);
      console.log("result;;", result);

      // Set the message in Vuex store
      store.dispatch("setRegistrationMessage", result.message[0] || "");
      store.dispatch("setUserEmail", email.value);
      store.dispatch("clearErrorMessage");

      // Redirect to the registration-code page
      router.push("/reset-password");
    } catch (error) {
      console.error("Registration error:", error.message);
      // Parse and display error messages
      try {
        // Attempt to parse detailed error messages
        const parsedErrors = JSON.parse(error.message);
        console.log("Parsed errors:", parsedErrors);

        errorMessages.value = parsedErrors;
      } catch {
        // Fallback if parsing fails
        errorMessages.value = { general: [error.message] };
      }
    } finally {
      isLoading.value = false;
      store.dispatch("setErrorMessage", "");
    }
  }
};
</script>
<style scoped>
.register-header-img {
  margin-bottom: 5%;
}
</style>
