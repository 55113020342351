<template>
  <div class="main-wrapper bg-gray-100 min-h-screen">
    <!-- Layout Header with Background Image -->
    <div class="relative bg-cover bg-center py-20 profile-background">
      <div
        class="absolute flex items-center justify-center bottom-0 left-0 right-0 bg-black w-24 h-24 rounded-full mx-auto"
        style="transform: translateY(50%)"
      >
        <div class="flex justify-center items-center">
          <span class="text-lg text-white font-bold">PE(.)</span>
        </div>
      </div>
    </div>

    <!-- Breadcrumb -->
    <breadcrumb :title="title" :text="text" :text1="text1" />

    <!-- Content Section -->
    <div class="content !py-20 !px-5">
      <div class="container mx-auto">
        <div class="flex flex-wrap -mx-4">
          <!-- Profile Sidebar -->
          <div class="w-full lg:w-1/4 px-4">
            <profilesidebar></profilesidebar>
          </div>

          <!-- Main Content -->
          <div class="w-full lg:w-3/4 px-4">
            <!-- Diagnosis Component -->
            <keep-alive>
              <patients-diagnosis></patients-diagnosis>
            </keep-alive>

            <!-- Your Information -->
            <div class="bg-white shadow-md rounded-lg p-6 my-6">
              <h2
                class="text-xl mb-6 flex gap-3 items-center font-semibold mb-4"
              >
                <div
                  class="rounded-full bg-black w-8 h-8 flex items-center justify-center"
                >
                  <i class="far fa-user text-[13px] !font-thin text-white"></i>
                </div>

                <i>Your Information</i>
              </h2>
              <div class="space-y-3">
                <div class="flex items-center justify-between">
                  <span class="font-semibold text-gray-600">Name:</span>
                  <span class="text-gray-800 text-left w-2/3">{{
                    userData.name
                  }}</span>
                </div>
                <div class="flex items-center justify-between">
                  <span class="font-semibold text-gray-600">Email:</span>
                  <span class="text-gray-800 w-2/3">{{ userData.email }}</span>
                </div>
              </div>
            </div>

            <!-- Settings Button -->
            <button
              @click="goToSettings"
              class="w-full py-3 my-3 border-2 text-black font-bold rounded-md hover:bg-black hover:text-white transition"
            >
              Settings
            </button>

            <!-- Logout Button -->
            <button
              @click="logout"
              class="w-full py-3 border-2 text-black font-bold rounded-md hover:bg-black/60 hover:text-white transition"
            >
              Logout
            </button>
            <div class="mt-3 w-full text-center mb-16">
              v0 0.0.1 (456789722) - v1
            </div>
            <!-- Call Doctor Button -->
          </div>
        </div>
      </div>
    </div>

    <bottom-navbar />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import patientsDiagnosis from "@/views/patients/diagnosis/patients-diagnosis-list.vue";

export default {
  components: {
    patientsDiagnosis,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const userData = ref({
      name: "",
      email: "",
    });

    onMounted(() => {
      const storedUserData = localStorage.getItem("userData");
      if (storedUserData) {
        const parsedUserData = JSON.parse(storedUserData);
        userData.value = parsedUserData;
        store.dispatch("setRegisteredUser", userData.value);
      } else {
        console.warn("No userData found in localStorage.");
      }
    });

    const logout = () => {
      store.dispatch("authentication/logout");
      router.push("/login");
    };

    return {
      title: "Profile Settings",
      text: "Home",
      text1: "Profile Settings",
      userData,
      logout,
    };
  },

  methods: {
    goToSettings() {
      this.$router.push("/settings");
    },
  },
};
</script>

<style scoped>
.profile-background {
  width: 100%;
  min-height: 14rem;
  object-fit: cover;
  font-family: Arial, sans-serif;
  background-image: url("../../assets/img/bg/profile-bg.webp");
  background-size: cover;
  background-position: center;
}
</style>
