<template>
  <div class="p-6 bg-white rounded-lg shadow-md max-w-2xl mx-auto">
    <!-- Back Button -->
    <button @click="goBack" class="back-arrow">
      <i class="fa-solid fa-chevron-left"></i>
      <!-- Using a Chevron Icon -->
    </button>
    <!-- Title -->
    <h2 class="text-2xl font-semibold mb-4">Support Settings</h2>

    <!-- Email -->
    <div class="mb-4">
      <h3 class="text-lg font-semibold">Email</h3>
      <p class="text-gray-700">support@example.com</p>
    </div>

    <!-- Phone Number -->
    <div class="mb-4">
      <h3 class="text-lg font-semibold">Phone Number</h3>
      <p class="text-gray-700">+1-234-567-890</p>
    </div>

    <!-- FAQ Section -->
    <div class="mt-6">
      <h3 class="text-xl font-semibold mb-2">Frequently Asked Questions</h3>

      <div class="faq mb-4">
        <h4 class="font-semibold text-gray-800">
          1. How do I reset my password?
        </h4>
        <p class="text-gray-600">
          You can reset your password by clicking on "Forgot Password" on the
          login page and following the instructions.
        </p>
      </div>

      <div class="faq mb-4">
        <h4 class="font-semibold text-gray-800">
          2. How can I contact customer support?
        </h4>
        <p class="text-gray-600">
          You can contact customer support via email at support@example.com or
          call us at +1-234-567-890.
        </p>
      </div>

      <div class="faq mb-4">
        <h4 class="font-semibold text-gray-800">
          3. How do I update my account information?
        </h4>
        <p class="text-gray-600">
          To update your account information, navigate to the Profile section
          and click on "Edit Profile."
        </p>
      </div>

      <div class="faq mb-4">
        <h4 class="font-semibold text-gray-800">
          4. What is the refund policy?
        </h4>
        <p class="text-gray-600">
          We offer a 30-day refund policy on all purchases. For more details,
          visit our Refund Policy page.
        </p>
      </div>

      <div class="faq mb-4">
        <h4 class="font-semibold text-gray-800">
          5. How do I deactivate my account?
        </h4>
        <p class="text-gray-600">
          You can deactivate your account by contacting our support team at
          support@example.com.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Supports",
  methods: {
    goBack() {
      this.$router.go(-1); // Navigates back to the previous route
    },
  },
};
</script>

<style scoped>
.faq {
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 10px;
}
</style>
