<template>
  <div class="modal" v-if="isVisible">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h2>Confirm Your Password</h2>
      <form @submit.prevent="confirmPassword">
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" v-model="password" id="password" class="form-control" required/>
          <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span>
        </div>
        <button type="submit" class="btn btn-primary">Confirm</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    redirectRoute: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      password: ''
    };
  },
  computed: {
    errorMessage() {
      return this.$store.getters['authentication/errorMessage'];
    },
    userEmail() {
      return this.$store.getters['registeredUser'].email;
    }
  },
  methods: {
    async confirmPassword() {
      const email = this.userEmail;
      const password = this.password;

      const response = await this.$store.dispatch('authentication/isPasswordValidate', {email, password});

      if (response.token) {
        this.$store.commit('setSuccessMessage', '');
        this.$emit('confirmed');
        this.$router.push(this.redirectRoute);
      } else {
        this.$store.commit('authentication/setErrorMessage', null);

        this.$emit('logout');
        this.$router.push('/login');
      }
    },
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
/* Modal Styles */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.text-danger {
  color: red;
}
</style>