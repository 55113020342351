import axiosInstance from "@/constants/axios";
import END_POINTS from "@/constants/endponsts";

// Create a new order with one or more items
export function createOrder(items) {
  const response = axiosInstance.post(END_POINTS.create_order, {
    items: items,
  });
  return response;
}

// Update an existing order with one or more items
export function updateOrder(orderId, items) {
  const response = axiosInstance.put(`${END_POINTS.update_order}/${orderId}`, {
    items,
  });
  return response;
}

// Delete an existing order by its ID
export function deleteOrder(orderId) {
  return axiosInstance.delete(`${END_POINTS.delete_order}/${orderId}`);
}

// Fetch a list of orders with pagination
export function getOrders(page, count) {
  const response = axiosInstance.get(END_POINTS.get_orders, {
    params: { page, count },
  });
  return response;
}

// checkout the order
export function checkoutPayment(orderId) {
  const response = axiosInstance.get(`${END_POINTS.checkout_order}/${orderId}`);
  return response;
}
