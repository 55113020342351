const END_POINTS = {
  // dashboard
  get_home_data: "/api/plan/home",
  // Existing Test Endpoints
  get_diagnosis: "/api/test/categories-list",
  get_category_details: "/api/test/category/",
  get_parameter_details: "/api/test/parameter/",

  // Post Endpoints
  get_post_categories_list: "/api/post/categories-list",
  get_category_detail: "/api/post/category/",
  get_post_detail: "/api/post/post/",

  // Plan Endpoints
  get_plans: "/api/plan/list", // Endpoint to fetch all plans
  get_plan_by_id: "/api/plan/item/", // Endpoint to fetch a single plan by ID

  //orders
  create_order: "/api/order",
  update_order: "/api/order",
  delete_order: "/api/order",
  get_orders: "/api/order",
  checkout_order: "/api/order/transaction",
};

export default END_POINTS;
