<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <!-- Page Content -->
    <div class="content mt-10">
      <div class="container mx-auto">
        <div class="flex justify-center">
          <div class="w-full md:w-1/2 lg:w-1/3 - p-4">
            <!-- Register Content -->
            <div class="flex flex-col items-center">
              <h3 class="text-xl font-semibold mb-4">Access to Your Health</h3>
              <img
                src="assets/img/supplements/supplement-3.jpg"
                alt="Health app register"
                class="rounded-lg mb-6 w-full"
              />

              <!-- Register Form -->
              <form @submit.prevent="submitForm" class="w-full">
                <div v-if="errorMessage" class="alert alert-danger mt-3">
                  {{ errorMessage }}
                </div>

                <div
                  v-if="Object.keys(errorMessages).length"
                  class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <div v-for="(messages, field) in errorMessages" :key="field">
                    <p
                      v-if="Array.isArray(messages)"
                      v-for="message in messages"
                      :key="message"
                    >
                      {{ message }}
                    </p>
                    <p v-else>{{ messages }}</p>
                  </div>
                </div>

                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2"
                    for="providedId"
                  >
                    Provided ID
                  </label>
                  <input
                    type="text"
                    id="providedId"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    v-model="providedId"
                    required
                  />
                  <p v-if="idError" class="text-red-500 text-xs italic">
                    {{ idError }}
                  </p>
                </div>

                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2"
                    for="email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    v-model="email"
                    required
                  />
                  <p v-if="emailError" class="text-red-500 text-xs italic">
                    {{ emailError }}
                  </p>
                </div>

                <div class="flex items-center justify-between">
                  <router-link
                    class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                    to="/login"
                  >
                    Already have an account?
                  </router-link>

                  <button
                    class="bg-black hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Signup
                  </button>
                </div>
              </form>
              <!-- /Register Form -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <indexfooter />
    <!-- Loading Spinner -->
    <loading-spinner v-if="isLoading" />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { registerUser } from "@/services/auth";

const store = useStore();
const router = useRouter();

const email = ref("");
const providedId = ref("");
const emailError = ref("");
const idError = ref("");
const isLoading = ref(false);
const errorMessage = computed(() => store.getters.errorMessage);
const errorMessages = ref({});

/*
onMounted(() => {
  store.dispatch('clearErrorMessage');
});
*/

const validateForm = () => {
  let isValid = true;

  errorMessages.value = {};

  // Validate Provided ID
  if (!providedId.value) {
    errorMessages.value.identifier = ["Provided ID is required."];
    isValid = false;
  }

  // Validate Email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email.value) {
    errorMessages.value.email = ["Email is required."];
    isValid = false;
  } else if (!emailRegex.test(email.value)) {
    errorMessages.value.email = ["Enter a valid email address."];
    isValid = false;
  }

  return isValid;
};

const submitForm = async () => {
  if (validateForm()) {
    isLoading.value = true;

    try {
      const userData = {
        email: email.value,
        identifier: providedId.value,
      };
      const result = await registerUser(userData);
      console.log("result;;", result);

      // Set the message in Vuex store
      store.dispatch("setRegistrationMessage", result.message[0] || "");
      store.dispatch("setUserEmail", email.value);
      store.dispatch("setErrorMessage", "");

      // Redirect to the registration-code page
      router.push("/register-step-two");
    } catch (error) {
      console.error("Registration error:", error.message);
      // Parse and display error messages
      try {
        // Attempt to parse detailed error messages
        const parsedErrors = JSON.parse(error.message);
        console.log("Parsed errors:", parsedErrors);

        errorMessages.value = parsedErrors;
      } catch {
        // Fallback if parsing fails
        errorMessages.value = { general: [error.message] };
      }
    } finally {
      isLoading.value = false;
      store.dispatch("setErrorMessage", "");
    }
  }
};
</script>
<style scoped>
.register-header-img {
  margin-bottom: 5%;
}
</style>
