<template>
  <!-- Page Content -->
  <div class="content login-page pt-0">
    <div class="container-fluid">
      <!-- Register Content -->
      <div class="account-content">
        <div class="row align-items-center">
          <div class="login-right">
            <div class="inner-right-login">
              <div class="login-header">
                <div class="logo-icon">
                  <img src="assets/img//logo.svg" alt="doccure-logo" />
                </div>
                <div class="step-list">
                  <ul>
                    <li>
                      <a href="javascript:void(0)" class="active-done">1</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" class="active-done">2</a>
                    </li>
                    <li><a href="javascript:void(0)" class="active">3</a></li>
                    <li><a href="javascript:void(0)">4</a></li>
                    <li><a href="javascript:void(0)">5</a></li>
                    <li><a href="javascript:void(0)">6</a></li>
                  </ul>
                </div>
                <form
                  @submit.prevent="handleSubmit"
                  id="profile_pic_form"
                  enctype="multipart/form-data"
                >
                  <div class="profile-pic-col">
                    <h3>Profile Picture</h3>
                    <div class="profile-pic-upload">
                      <div class="cam-col">
                        <img
                          src="assets/img//icons/camera.svg"
                          id="prof-avatar"
                          alt="camera-image"
                          class="img-fluid"
                        />
                      </div>
                      <span>Upload Profile Picture</span>
                      <input
                        type="file"
                        id="profile_image"
                        name="profile_image"
                        @change="handleFileUpload"
                      />
                      <!-- Display file error -->
                      <div v-if="fileError" class="text-danger">
                        {{ fileError }}
                      </div>
                      <!-- Display selected file info -->
                      <div v-if="selectedFile" class="file-info">
                        <span>Selected File: {{ selectedFile.name }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5">
                    <router-link
                      to="/patient-register-step2"
                      class="btn btn-primary w-100 btn-lg login-btn step1_submit"
                      >continue
                    </router-link>
                  </div>
                </form>
              </div>
            </div>
            <div class="login-bottom-copyright">
              <span
                >© {{ new Date().getFullYear() }} Doccure. All rights
                reserved.</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- /Register Content -->
    </div>
  </div>
  <!-- /Page Content -->
</template>
<script>
export default {
  data() {
    return {
      selectedFile: null,
      fileError: "",
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.fileError = ""; // Reset error message

      if (file) {
        // Check file size (e.g., limit to 2MB)
        const maxSize = 2 * 1024 * 1024; // 2MB
        if (file.size > maxSize) {
          this.fileError = "File size exceeds 2MB.";
          this.selectedFile = null; // Clear the selected file
          return;
        }

        // Check file type (e.g., accept only images)
        const validTypes = ["image/jpeg", "image/png", "image/gif"];
        if (!validTypes.includes(file.type)) {
          this.fileError = "Only JPG, PNG, and GIF files are allowed.";
          this.selectedFile = null; // Clear the selected file
          return;
        }

        // If file is valid, save it
        this.selectedFile = file;
      }
    },
    handleSubmit() {
      if (this.fileError) {
        alert(this.fileError); // Display the file error
        return;
      }

      // Perform file upload logic here (e.g., send to server)
      if (this.selectedFile) {
        console.log("Uploading file:", this.selectedFile);
        // Implement actual upload logic here
      }

      // Navigate to the next step
      this.$router.push("/patient-register-step2");
    },
  },
};
</script>
