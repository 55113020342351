<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="max-w-2xl mx-auto">
    <!-- Search Input -->
    <div class="search-component flex items-end justify-end p-6">
      <div class="w-full">
        <h2 class="text-white/65 mb-3">Search</h2>
        <input
          v-model="searchQuery"
          @input="performSearch"
          class="border border-gray-300 rounded-sm p-2 w-full"
          type="text"
          placeholder="Search..."
        />
      </div>
    </div>

    <!-- Loading Indicator -->
    <div v-if="loading" class="text-center mt-2">Loading...</div>

    <!-- Search Results -->
    <div class="m-4 space-y-4" v-if="filteredResults.length > 0 && !loading">
      <h3 class="text-lg font-semibold">Search Results:</h3>
      <ul class="list-disc pl-4">
        <li
          v-for="(item, index) in filteredResults"
          :key="index"
          class="result-item"
        >
          <router-link
            class="flex items-center justify-between w-full my-4"
            :to="{
              name: 'CategoryDetail',
              params: { categoryName: item.id },
            }"
          >
            <div>{{ item.name }}</div>
            <i class="fas fa-chevron-right"></i>
          </router-link>
        </li>
      </ul>
    </div>

    <!-- No Results Found -->
    <div v-else-if="!loading" class="m-4 text-gray-500">
      <p>No results found for "{{ searchQuery }}".</p>
    </div>
  </div>
  <bottom-navbar />
</template>

<script>
import { postDiagnosis } from "@/services/posts";

export default {
  data() {
    return {
      searchQuery: "",
      results: [],
      filteredResults: [],
      loading: false,
    };
  },
  mounted() {
    this.fetchDiagnosisData();
  },
  methods: {
    async fetchDiagnosisData() {
      this.loading = true;
      try {
        const response = await postDiagnosis();
        this.results = response.data;
        this.filteredResults = this.results;
      } catch (error) {
        console.error("Error fetching diagnosis data:", error);
      }
      this.loading = false;
    },
    performSearch() {
      this.loading = true;
      const query = this.searchQuery.trim().toLowerCase();
      this.filteredResults = query
        ? this.results.filter((item) => item.name.toLowerCase().includes(query))
        : [];
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.search-component {
  width: 100%;
  min-height: 14rem;
  font-family: Arial, sans-serif;
  background-image: url("../../assets/img/bg/search.webp");
  background-size: cover;
  background-position: center;
}

.result-item {
  border-bottom: 1px solid #ccc; /* Light grey border for each item */
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}
</style>
