<template>
  <div class="p-6 bg-white rounded-lg shadow-md max-w-3xl mx-auto">
    <!-- Back Button -->
    <button @click="goBack" class="mb-4">
      <i class="fa fa-chevron-left text-2xl"></i>
    </button>

    <!-- Loading Spinner -->
    <div v-if="isLoading" class="flex justify-center items-center py-8">
      <i class="fa fa-spinner fa-spin text-3xl text-gray-600"></i>
    </div>

    <!-- Content Section -->
    <div v-else>
      <!-- Health Area Title -->
      <h2 class="text-2xl font-semibold mb-4">{{ healthAreaName }}</h2>

      <!-- Custom Circular Progress -->
      <div class="relative flex justify-center items-center mb-4">
        <div
          class="circle-progress"
          :style="{ '--progress': progressRate + '%' }"
        >
          <div class="circle-progress-inner">
            <span class="text-xl font-bold">
              {{ completedTests }}/{{ totalTests }}
            </span>
          </div>
        </div>
      </div>
      <p class="mx-auto w-full text-center" v-if="progressRate === 100">
        All parameters tested
      </p>

      <!-- Your Values -->
      <h3 class="text-xl font-semibold mb-4">Your Values</h3>
      <div class="space-y-4 mb-8">
        <div
          v-for="(value, index) in yourValues"
          :key="index"
          class="flex justify-between items-center p-4 border rounded-lg shadow cursor-pointer"
          @click="goToValueDetails(value.id)"
        >
          <span class="text-lg font-semibold">{{ value.name }}</span>
          <span class="text-gray-500">{{ value.limit }}</span>
          <div class="flex items-center gap-2">
            <p class="m-0">{{ value.value }}</p>
            <i class="fa fa-chevron-right text-gray-500"></i>
          </div>
        </div>
      </div>

      <!-- Untested Values -->
      <h3 class="text-xl font-semibold mb-4">Untested Values</h3>
      <div class="space-y-4">
        <div
          v-for="(untested, index) in untestedValues"
          :key="index"
          class="flex justify-between items-center p-4 border rounded-lg shadow cursor-pointer"
          @click="goToValueDetails(untested.id)"
        >
          <span class="text-lg font-semibold">{{ untested.name }}</span>
          <i class="fa fa-chevron-right text-gray-500"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "PatientsDiagnosisItem",
  computed: {
    ...mapState("tests", {
      categories: (state) => state.categories,
      isLoading: (state) => state.isLoading,
    }),
    healthAreaName() {
      const category = this.categories[this.$route.params.id];
      return category ? category.name : "";
    },
    yourValues() {
      const category = this.categories[this.$route.params.id];
      return category ? category.yourValues : [];
    },
    untestedValues() {
      const category = this.categories[this.$route.params.id];
      return category ? category.untestedValues : [];
    },
    completedTests() {
      return this.yourValues.length;
    },
    totalTests() {
      return this.yourValues.length + this.untestedValues.length;
    },
    progressRate() {
      return this.totalTests > 0
        ? (this.completedTests / this.totalTests) * 100
        : 0;
    },
  },
  mounted() {
    const diagnosisId = this.$route.params.id;
    this.fetchCategoryDetails(diagnosisId);
  },
  methods: {
    ...mapActions("tests", ["fetchCategoryDetails"]),
    goBack() {
      this.$router.back();
    },
    goToValueDetails(id) {
      this.$router.push({ name: "TestValueDetails", params: { id } });
    },
  },
};
</script>

<style scoped>
.circle-progress {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: conic-gradient(#4caf50 var(--progress), #ddd 0);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circle-progress-inner {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
