<template>
  <!-- Page Content -->
  <div class="content login-page pt-0">
    <div class="container-fluid">
      <!-- Register Content -->
      <div class="account-content">
        <div class="row align-items-center">
          <div class="login-right">
            <div class="inner-right-login">
              <div class="login-header">
                <div class="logo-icon">
                  <img src="assets/img//logo.svg" alt="doccure-logo" />
                </div>
                <div class="step-list">
                  <ul>
                    <li>
                      <a href="javascript:void(0)" class="active-done">1</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" class="active-done">2</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" class="active-done">3</a>
                    </li>
                    <li><a href="javascript:void(0)" class="active">4</a></li>
                    <li><a href="javascript:void(0)">5</a></li>
                    <li><a href="javascript:void(0)">6</a></li>
                  </ul>
                </div>
                <form
                  @submit.prevent="submitForm"
                  id="personal_details"
                  enctype="multipart/form-data"
                >
                  <div class="text-start mt-2">
                    <h4 class="mt-3">Select Your Gender</h4>
                  </div>
                  <div class="select-gender-col">
                    <div class="row">
                      <div class="col-6 pe-2">
                        <input
                          type="radio"
                          id="test1"
                          name="gender"
                          value="Male"
                          v-model="form.gender"
                        />
                        <label for="test1">
                          <span class="gender-icon"
                            ><img
                              src="assets/img//icons/male.png"
                              alt="male-icon"
                          /></span>
                          <span>Male</span>
                        </label>
                      </div>
                      <div class="col-6 ps-2">
                        <input
                          type="radio"
                          id="test2"
                          name="gender"
                          value="Female"
                          v-model="form.gender"
                        />
                        <label for="test2">
                          <span class="gender-icon"
                            ><img
                              src="assets/img//icons/female.png"
                              alt="female-icon"
                          /></span>
                          <span>Female</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="pregnant-col pt-4">
                    <div>
                      <div
                        class="remember-me-col d-flex justify-content-between"
                      >
                        <span class="mt-1">Are you Pregnant</span>
                        <label class="custom_check">
                          <input type="checkbox" v-model="form.pregnant" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="step-process-col mt-4">
                    <div class="mb-3" v-if="form.pregnant">
                      <label class="mb-2">Pregnancy Term</label>
                      <vue-select
                        :options="sorting1"
                        placeholder="Select Your Pregnancy Month"
                        v-model="form.pregnancyTerm"
                      ></vue-select>
                    </div>
                    <div class="mb-3">
                      <label class="mb-2">Your Weight</label>
                      <div class="row">
                        <div class="col-7 pe-2">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.weight"
                          />
                        </div>
                        <div class="col-5 ps-2">
                          <vue-select
                            :options="sorting6"
                            placeholder="Kg"
                            v-model="form.weightUnit"
                          ></vue-select>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label class="mb-2">Your Height</label>
                      <div class="row">
                        <div class="col-7 pe-2">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.height"
                          />
                        </div>
                        <div class="col-5 ps-2">
                          <vue-select
                            :options="sorting7"
                            placeholder="Cm"
                            v-model="form.heightUnit"
                          ></vue-select>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label class="mb-2">Your Age</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.age"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="mb-2">Blood Type</label>
                      <vue-select
                        :options="sorting2"
                        placeholder="Select your blood group"
                        v-model="form.bloodType"
                      ></vue-select>
                    </div>
                    <div class="mb-3">
                      <label class="mb-2">Heart Rate</label>
                      <vue-select
                        :options="sorting3"
                        placeholder="Select Your Heart Rate"
                        v-model="form.heartRate"
                      ></vue-select>
                    </div>
                    <div class="mb-3">
                      <label class="mb-2">Blood Pressure</label>
                      <vue-select
                        :options="sorting4"
                        placeholder="Select Your Blood Pressure"
                        v-model="form.bloodPressure"
                      ></vue-select>
                    </div>
                    <div class="mb-3">
                      <label class="mb-2">Glucose Level</label>
                      <vue-select
                        :options="sorting5"
                        placeholder="Select Your Glucose Level"
                        v-model="form.glucoseLevel"
                      ></vue-select>
                    </div>
                    <div class="mb-3">
                      <label class="mb-2">Allergies</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.allergies"
                      />
                    </div>
                    <div class="checklist-col pregnant-col">
                      <div
                        class="remember-me-col d-flex justify-content-between"
                      >
                        <span class="mt-1"
                          >Do you have any pre-existing conditions?</span
                        >
                        <label class="custom_check">
                          <input type="checkbox" v-model="form.hasConditions" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div v-if="form.hasConditions">
                        <div class="condition_input">
                          <input
                            type="text"
                            v-model="form.conditions"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div
                        class="remember-me-col d-flex justify-content-between"
                      >
                        <span class="mt-1"
                          >Are you currently taking any medication</span
                        >
                        <label class="custom_check">
                          <input type="checkbox" v-model="form.hasMedicine" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div v-if="form.hasMedicine">
                        <div class="medicine_input">
                          <input
                            type="text"
                            v-model="form.medicineName"
                            class="form-control"
                            placeholder="Enter medicine name"
                          />
                          <input
                            type="text"
                            v-model="form.dosage"
                            class="form-control"
                            placeholder="Enter dosage"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5">
                    <button
                      type="submit"
                      class="btn btn-primary w-100 btn-lg login-btn step2_submit"
                    >
                      Continue
                    </button>
                  </div>
                  <div v-if="error" class="alert alert-danger mt-3">
                    {{ error }}
                  </div>
                </form>
              </div>
            </div>
            <div class="login-bottom-copyright">
              <span
                >© {{ new Date().getFullYear() }} Doccure. All rights
                reserved.</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- /Register Content -->
    </div>
  </div>
  <!-- /Page Content -->
</template>

<script>
export default {
  data() {
    return {
      sorting1: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      sorting2: ["A-", "A+", "B-", "B+", "AB-", "AB+", "O-", "O+"],
      sorting3: ["1", "2"],
      sorting4: ["1", "2"],
      sorting5: ["Select Your Glucose Level", "1", "2"],
      sorting6: ["Kg"],
      sorting7: ["cm", "ft"],
      form: {
        gender: "",
        pregnant: false,
        pregnancyTerm: "",
        weight: "",
        weightUnit: "",
        height: "",
        heightUnit: "",
        age: "",
        bloodType: "",
        heartRate: "",
        bloodPressure: "",
        glucoseLevel: "",
        allergies: "",
        hasConditions: false,
        conditions: "",
        hasMedicine: false,
        medicineName: "",
        dosage: "",
      },
      error: null,
    };
  },
  methods: {
    validateForm() {
      if (!this.form.gender) {
        return "Gender is required";
      }
      if (this.form.pregnant && !this.form.pregnancyTerm) {
        return "Pregnancy term is required";
      }
      if (!this.form.weight) {
        return "Weight is required";
      }
      if (!this.form.height) {
        return "Height is required";
      }
      if (!this.form.age) {
        return "Age is required";
      }
      if (!this.form.bloodType) {
        return "Blood type is required";
      }
      return null;
    },
    submitForm() {
      // this.error = this.validateForm();
      if (!this.error) {
        // Navigate to the next step
        this.$router.push("/patient-register-step3");
      }
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
